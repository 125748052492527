<template>
  <div id="app" :class="{app: !$route.meta.layout, app_mobile: isMobile}">
    
      <component :is="layout" :style="{ background: $vuetify.theme.themes.light.background}"/>

  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {api} from './api/api';

const default_layout = 'default';

export default {
  name: 'App',
  created() {
    window.addEventListener('resize', this.resize);
    this.resize();
    this.getFestival();
  },
  watch: {
    $route: {
      immediate: true,
      async handler(to) {
        if(this.$route.name === 'desk.index') {
          await this.getFestival();
        }
        if(this.festivalInfo) {
          const ruTitle = this.festivalInfo.short_title_ru || this.festivalInfo.title_ru
          const enTitle = this.festivalInfo.short_title_en || this.festivalInfo.title_en

          document.title = this.language === 'ru' ?  `${ruTitle}:${to.meta.title}` : `${enTitle}:${to.meta.title_en}`
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      isDesktop: 'app/isDesktop',
      isMobile: 'app/isMobile',
      language: 'lang/language',
      festivalInfo: 'desk/festivalInfo',
    }),
    layout() {
        return (this.$route.meta.layout || default_layout) + '-layout';
    }
  },
  methods: {
    ...mapActions({
      setPlatform: 'app/setPlatform',
      changeLanguage: 'lang/changeLanguage'
    }),
    ...mapMutations({
      setFestivalInfo: 'desk/setFestivalInfo',
      setCountries: 'desk/setCountries'
    }),
    resize() {
      if (window.innerWidth > 600) {
        this.setPlatform('desktop');
      } else {
        this.setPlatform('mobile');
      }
    },
    setupScheme(main_color = '#d31f34',  gradient_color = '#88101d', extra_color = '#bb9657'){
      document.documentElement.style.setProperty('--main_color', main_color);
      document.documentElement.style.setProperty('--gradient_color', gradient_color);
      document.documentElement.style.setProperty('--extra_color', extra_color);
    },
    async getFestival() {
      
      const {data} = await api.festival.get(this.$route.params.slug)
      
      if(data.festival.languages.length < 2) {
        this.changeLanguage(data.festival.languages[0].short_name)
      }
      
      this.setupScheme(data.festival.interface.main_color, data.festival.interface.gradient_color, data.festival.interface.extra_color)

      this.setFestivalInfo(data.festival);
      this.setCountries(data.countries);
    },
  }
}
</script>

<style lang="scss">
#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.app {
  padding-top: 92px;

  &.app_mobile {
    padding-top: 19%;
  }
}
</style>
