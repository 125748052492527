import Vue from "vue";
import Vuetify from "vuetify/lib";
import ru from "@/constants/lang/ru";

Vue.use(Vuetify);

import colors from "vuetify/lib/util/colors";
export default new Vuetify({
  lang: {
    locales: { ru },
    current: "ru",
  },
  icons: {
    iconfont: "mdi",
  },
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: "#21CFF3",
        accent: "#FF4081",
        secondary: "#ffe18d",
        success: "#4CAF50",
        info: "#2196F3",
        warning: "#FB8C00",
        error: "#FF5252",
      },
      light: {
        primary: colors.indigo.base,
        primaryVariant: colors.indigo.darken3,
        secondary: colors.blueGrey.lighten3,
        accent: colors.shades.black,
        error: "#B00020",
        success: "#4CAF50",
        info: "#2196F3",
        warning: "#FB8C00",
        background: "#f5f5f6",
      },
    },
  },
});
