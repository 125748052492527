<template>
  <div class="auth-error">
    <div class="auth-error__icon">
      <svg width="125" height="115" viewBox="0 0 125 115" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
      <path d="M111.664 114.001H13.3981C11.2584 114.001 9.15592 113.438 7.30018 112.367C5.44444 111.297 3.90018 109.756 2.82118 107.899C1.74219 106.042 1.16612 103.933 1.15036 101.782C1.13459 99.6319 1.67967 97.5147 2.73132 95.6418L52.3328 7.33173C53.3995 5.41189 54.9566 3.81292 56.8434 2.69986C58.7302 1.58681 60.8784 1 63.0662 1C65.2541 1 67.4023 1.58681 69.2891 2.69986C71.1759 3.81292 72.733 5.41189 73.7997 7.33173L122.331 95.6418C123.377 97.5156 123.918 99.6315 123.899 101.78C123.881 103.928 123.304 106.035 122.226 107.89C121.148 109.745 119.607 111.285 117.754 112.357C115.901 113.429 113.802 113.996 111.664 114.001V114.001Z" stroke="#BB9657" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M62.4637 95.2393C65.4093 95.2393 67.7971 92.8394 67.7971 89.8789C67.7971 86.9185 65.4093 84.5186 62.4637 84.5186C59.5182 84.5186 57.1303 86.9185 57.1303 89.8789C57.1303 92.8394 59.5182 95.2393 62.4637 95.2393Z" stroke="#BB9657" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M59.2641 73.7959L58.1972 35.8736C58.1815 35.0035 58.5093 34.1626 59.1089 33.5348C59.7086 32.907 60.5312 32.5435 61.3968 32.5239H63.5306C63.9658 32.5108 64.3988 32.5895 64.8019 32.755C65.2049 32.9205 65.5689 33.1691 65.8704 33.4848C66.1719 33.8004 66.4042 34.1761 66.5523 34.5876C66.7004 34.999 66.761 35.4371 66.7303 35.8736L65.6634 73.7959C65.655 74.2095 65.5655 74.6174 65.4001 74.9962C65.2347 75.3751 64.9966 75.7174 64.6994 76.0037C64.4022 76.2899 64.0518 76.5145 63.6682 76.6644C63.2845 76.8144 62.8753 76.8869 62.4637 76.8778C62.0521 76.8875 61.6425 76.8153 61.2587 76.6655C60.8748 76.5157 60.5242 76.2912 60.2269 76.0049C59.9296 75.7185 59.6915 75.376 59.5263 74.9969C59.3611 74.6178 59.272 74.2097 59.2641 73.7959V73.7959Z" stroke="#BB9657" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0">
      <rect width="125" height="115" fill="white"/>
      </clipPath>
      </defs>
      </svg>
    </div>
    <div class="auth-error__title">
      {{locale.auth.error}}
    </div>
    <div class="auth-error__description">
     {{text}}
    </div>
     <div class="auth-error__action">
     <button @click="$emit('close', false)">{{locale.buttons.back}}</button>
    </div>
  </div>
</template>


<script>
import { mapGetters} from 'vuex';

export default ({
  props: {
    text: {
      required: false,
    }
  },
  computed: {
    ...mapGetters({
      locale: 'lang/locale',
      language: 'lang/language',
      isDesktop: 'app/isDesktop',
      isMobile: 'app/isMobile'
    }),
  },
  methods: { }
})
</script>

<style lang="scss">
.auth-error {
  width: 500px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  background: #fff;
  padding: 50px;
  position: relative;

  @media screen and (max-width: 768px) {
    padding: 0 20px;
    min-width: 300px;
    min-height: 300px;
    width: 100%;
    height: 100%;
  }

  &__title {
    margin-top: 10px;
    width: 100%;
    font-size: 42px;
    font-weight: bold;
  }

  &__description {
    width: 100%;

    font-size: 23px;
    font-weight: 300;
  }

  &__action {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;

    button {
    padding: 5px 18px; 

    background: var(--extra_color);

    color: #FFFFFF
    }
  }
  
}
</style>