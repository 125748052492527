<template>
  <span class="sorter" @click="$emit('sort',  sortBy)">
    {{ name }}
    <span class="arrows">

        <span :class="{ active: isActive && sortedDesc}">  <svg width="11" height="15" viewBox="0 0 11 15" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"><path
            d="M0.900944 6.074L5.17114 0.000634069L9.44134 6.074H0.900944Z" fill="#949494"/>   </svg></span>
        <span :class="{ active: isActive && !sortedDesc}" class="down">  <svg width="11" height="15" viewBox="0 0 11 15"
                                                                              fill="none"
                                                                              xmlns="http://www.w3.org/2000/svg"><path
            d="M9.44134 7.92844L5.17114 14.0018L0.900944 7.92844H9.44134Z" fill="#949494"/>   </svg></span>

    </span>
  </span>
</template>

<script>
export default {
  name: "sort-by-component",
  props: {
    sortBy: {
      type: String,
      default: null
    },
    sortedBy: {
      type: String,
      default: null
    },
    sortedDesc: {
      type: Boolean,
      default: null
    },
    name: {
      type: String,
      default: null
    }
  },
  computed: {
    isActive() {
      return this.sortedBy === this.sortBy;
    }
  }
}
</script>

<style scoped lang="scss">
.sorter {
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 5px;


  .arrows {
    display: flex;
    flex-flow: column;

    span {
      height: 10px;
      opacity: 0.5;

      &.down {
        svg {
          position: relative;
          top: -5px;
        }
      }

      &.active {
        opacity: 1;
      }


    }


  }
}
</style>