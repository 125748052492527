<template>
 <div class="nav-up" :class="{mobile: isMobile}" v-if="festivalInfo">
   <div class="container-nav" >
    <div class="nav-up__logo" :class="{logo_mobile: isMobile}"  @click="setLink({id: 1, path: 'desk.index'})">
          <template v-if="festivalInfo && festivalInfo.interface && festivalInfo.interface.logo && festivalInfo.interface.logo.url">
            <img :src="festivalInfo.interface.logo.url" alt="">
          </template>
          <template v-else>
            <img src="@/assets/images/logo.png" alt="">
          </template>
          
    </div>
    <div class="nav-up__mobile-menu">
      <v-icon color="var(--extra_color)" @click="drawer = true">mdi-menu</v-icon>
    </div>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      v-if="menuItems.length !== 0"
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
         <template v-for="(link) in menuItems">
            <v-list-item class="link" @click="setLink(link)"  :class="{active: selectedItem  === link.id}" :key="link.id">
              <v-list-item-title uppercase>{{locale.nav.find(el => el.id === link.id).value}}</v-list-item-title>
                
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <div class="nav-up__container" v-if="festivalInfo">
     <div class="actions"  v-if="festivalInfo.languages.length > 1">
       <div class="actions__lang">
        <button class="btn--lang" @click="changeLang">Рус | Eng</button>
       </div>
     </div>
     <div class="nav-up__links">
     <template v-for="(link, id) in menuItems">
        <div class="link" @click="setLink(link)" :class="{active: selectedItem === link.id, noPlace: !checkDate()}" :key="link.id" v-if="id !== menuItems.length - 1">
            {{locale.nav.find(el => el.id === link.id).value}}
        </div>
         <div class="link" @click="setLink(link)" :class="{active: selectedItem === link.id, noPlace: !checkDate(), 'mr-0': checkRegistration() === false}" :key="link.id" v-else>
            {{locale.nav.find(el => el.id === link.id).value}}
        </div>
     </template>
     <div class="nav-up__links--registration" v-if="checkRegistration()">
       <button @click="toRegistration" class="registration-btn">{{ locale.buttons.registration }}</button>
     </div>
    </div>
   </div>
  </div>
 </div>
</template>


<script>
import {menu} from '@/constants/menu';
import { mapGetters, mapActions} from 'vuex';

export default {
  name: 'NavUp',
  data() {
    return {
      selectedItem: 1,
      drawer: null,
      group: null,
      registration_type: ['optional']
    }
  },
  mounted() {
    if (this.$route.params && this.$route.path.includes('/trial')) {
      this.selectedItem = 3;
      return
    }

    if (this.$route.params && this.$route.path.includes('/table')) {
      this.selectedItem = 4;
      return
    }



    this.selectedItem = menu.find((el) => el.path.includes(this.$route.name)).id
  },
  computed: {
    ...mapGetters({
      locale: 'lang/locale',
      language: 'lang/language',
      isDesktop: 'app/isDesktop',
      isMobile: 'app/isMobile',
      festivalInfo: 'desk/festivalInfo',
    }),
    menuItems() {
      if(this.festivalInfo) {
        if(!this.festivalInfo.interface.map_image) {
          return menu.filter(el => el.id !== 4);
        }

         return  menu;
      }
      return [];
     
    }
  },
  methods: {
    ...mapActions({
      changeLanguage: 'lang/changeLanguage'
    }),
    toRegistration() {
      this.$router.push({name: "registration.index", params: {slug: this.$route.params.slug }})
    },
    setLink(item) {
      this.selectedItem = item.id
      this.$router.push({name: item.path, params: {slug: this.$route.params.slug}})
    },
    changeLang() {
      if(this.language === 'ru') {
        this.changeLanguage('en')
        return
      }

      if(this.language === 'en') {
        this.changeLanguage('ru')
        return
      }
    },
    checkDate() {
      const today = new Date().getTime();
      const start = new Date(this.festivalInfo.opening_date).getTime();
      const end = new Date(this.festivalInfo.ending_date).getTime();
      
      if(start <= today && end >= start) {
        return true
      }

      return false
    },
    checkRegistration() {
      if (!this.festivalInfo){
        return false;
      }

      if (this.festivalInfo.settings && this.festivalInfo.settings.registration_type === 'personal' && !this.festivalInfo.is_finished){
          return true;
      }

      return false;

    }
  }
}
</script>

<style lang="scss">
.nav-up {
  width: 100%;


  &__logo {
     max-height: 65px;
     max-width: 295px;

     img {
        max-height: 65px;
        object-fit: cover;
     }
  }

  .v-list-item__title {
    text-align: start;
  }

  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;

  z-index: 1003;

  background: #fff;

  @media screen and (max-width: 1030px) {
    padding: 5px 15px;
  }

  &__mobile-menu {
    display: none;

    @media screen and (max-width: 1030px) {
      display: block;
      margin-left: auto;
    }
  }

  &__logo {
    &.logo_mobile {
        
          display: flex;
          justify-content: flex-start;

          img {
            max-width: 90%;
          }
        
      }
  }

  .container-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    max-width: 1500px;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 5px;

    &__lang {
      display: flex;
      align-items: center;
      margin-left: 10px;

      font-size: 12px;
      font-weight: normal;

      color: var(--main_color);

      .btn--lang {
        font-size: 14px;
      }

      @media screen and (max-width: 1030px) {
        display: none;
      }
    }

    &__registration {
      display: flex;
      align-items: center;

      font-size: 12px;
      font-weight: normal;

      color: #3C3C3C;
    }
  }

  &__links {
    display: flex;
    align-items: center;

    .link {
      margin-right: 20px;

      font-size: 16px;
      text-transform: uppercase;

      cursor: pointer;

      position: relative;

      color: #3C3C3C;

      &:hover::before {
        transform: scaleX(1);
        transform-origin: left;
      }

      &::before {
        content: "";

        width: 100%;
        height: 2px;

        position: absolute;
        bottom: -3px;
        left: 0;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform .15s ease-in-out;

        background-color: #bb9657;
      }

      &.active {
        box-shadow: 0 3px 0px -1px  var(--extra_color);

        font-weight: bold;
      }

      &.noPlace:nth-child(5) {
        margin-right: 0px;
      }
    }

    &--registration {

      .registration-btn {
        padding: 10px 34px 11px 30px; 

        background: var(--extra_color);

        color: #FFFFFF;

        font-size: 16px;
      }
      
    }

    @media screen and (max-width: 1030px) {
      display: none
    }
  }
}
</style>
