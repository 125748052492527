<template>
  <v-sheet color="#ffffff" class="pa-6">
    <cropper
        ref="cropper"
        class="cropper"
        :src="image"
        :debounce="false"
        @change="onChange"
        :stencil-props="{aspectRatio: 1}"
    ></cropper>
    <div class="d-flex flex-row align-end">
      <preview
          class="preview-result mt-3 mr-4"
          :width="120"
          :height="120"
          :image="result.image"
          :coordinates="result.coordinates"
      />
      <preview
          class="preview-result mt-3"
          :width="60"
          :height="60"
          :image="result.image"
          :coordinates="result.coordinates"
      />
    </div>
    <v-row>
      <v-col class="pr-0">
        <button class="secondary_blue" @click="$emit('close')" full-width small>{{locale.buttons.back}}</button>
      </v-col>
      <v-col>
        <button class="btn-img" @click="$emit('setImage', $refs.cropper)" full-width small>{{locale.buttons.save}}</button>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { Cropper, Preview } from 'vue-advanced-cropper'
import { mapGetters} from 'vuex';
import 'vue-advanced-cropper/dist/style.css';
export default({
  components: {
    Cropper,
    Preview
  },
  props: {
    image: {
      readonly: true,
      type: String,
    }
  },
  data() {
    return({
      result: {
        coordinates: null,
        image: null
      }
    })
  },
  computed: {
    ...mapGetters({
      locale: 'lang/locale',
      language: 'lang/language',
      countries: 'desk/countries',
    }),
  },
  methods: {
     onChange(e){
       this.result.coordinates = e.coordinates;
       this.result.image = e.image;
    }
  }
})

</script>

<style scoped>
.cropper {
  max-height: 350px;
}

.btn-img {
  padding: 5px 18px; 

  background: var(--extra_color);

  color: #FFFFFF
}

.secondary_blue {
  padding: 5px 18px; 
}
</style>
