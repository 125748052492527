import Vue from "vue";
import App from "./App.vue";
import Vuelidate from "vuelidate";

import MainLayout from "@/layouts/MainLayout.vue";
import LoginLayout from "@/layouts/LoginLayout.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import * as VeeValidate from "vee-validate";
import ru from "vee-validate/dist/locale/ru.json";
import vPlayBack from "v-playback";
import VueSocialSharing from "vue-social-sharing";
import VueMask from "v-mask";
import VueVideoPlayer from "vue-video-player";
import scroll from "vue-infinite-auto-scroll";
import VueQr from "vue-qr";
import vueDebounce from "vue-debounce";

Vue.use(vueDebounce);
Vue.use(vPlayBack);
Vue.use(VueQr);
Vue.use(VueSocialSharing);
Vue.use(VueMask);
Vue.use(Vuelidate);
Vue.use(VeeValidate, { inject: false });
Vue.use(VueVideoPlayer);
Vue.use(scroll);

VeeValidate.localize("ru", ru);
Vue.component("default-layout", MainLayout);
Vue.component("no-sidebar-layout", LoginLayout);

Vue.filter("formatDate", function(value) {
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }

  if (value) {
    let d = new Date(value);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(".");
  }
});

Vue.filter("numberFormat", function(value) {
  return Number.isInteger(value)
    ? value
    : (Math.round(value * 100) / 100).toFixed(2);
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
