export default {
  namespaced: true,
  state: {
    platform: "desktop",
    notification: {
      show: false,
      timeout: 5000,
      text: null,
    },
    error: {
      show: false,
      timeout: 5000,
      object: null,
    },
  },
  actions: {
    setPlatform: ({ commit }, payload) => {
      commit("SET_PLATFORM", payload);
    },
    error: ({ commit }, payload) => {
      commit("SET_ERROR", { show: true, timeout: 5000, object: payload });
    },
  },
  getters: {
    isMobile: (state) => {
      return state.platform === "mobile";
    },
    isDesktop: (state) => {
      return state.platform === "desktop";
    },
  },
  mutations: {
    ["SET_ERROR"]: (state, payload) => {
      state.error = payload;
    },
    ["SET_PLATFORM"]: (state, payload) => {
      state.platform = payload;
    },
  },
};
