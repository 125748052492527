export const menu = [
  {
    id: 1,
    text: "Главная",
    path: "desk.index",
  },
  {
    id: 2,
    text: "Турнирная таблица",
    path: "table.index",
  },
  {
    id: 3,
    text: "Испытания",
    path: "trial.list.index",
  },
  {
    id: 4,
    text: "Карта фестиваля",
    path: "map.index",
  },
  {
    id: 5,
    text: "Контакты",
    path: "contacts.index",
  },
];
