<template>
  <div class="trial-info-container">
    <div class="trial-info">
      <div class="trial-info__header">
        <button class="btn-back" @click="goBack">{{ locale.trial.btn_back }}</button>
      </div>
      <div class="trial-info__main" v-if="exerciseItem">
        <div class="trial-info__main--content">
          <div class="trial-info__main--content-title">
            {{ language === 'ru' ? exerciseItem.exercise.title_ru : exerciseItem.exercise.title_en }}
          </div>
          <div class="trial-info__main--content-text">
            {{ language === 'ru' ? exerciseItem.exercise.description_ru : exerciseItem.exercise.description_en }}
          </div>

          <div class="trial-info__main--content-video" v-if="exerciseItem.exercise.video_link">
            <iframe :src="`https://www.youtube.com/embed/${getYouTubeId(exerciseItem.exercise.video_link)}`"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
          </div>
        </div>
        <div class="trial-info__main--side-content">
          <div class="trial-info__main--side-content--sex">
            <div class="trial-info__main--side-content--sex-title">{{ locale.trial.labels.sex }}</div>
            <div class="trial-info__main--side-content--sex-radio">
              <v-radio-group
                  v-model="sex"
                  :row="isDesktop"
                  :column="isMobile"
                  @change="(e) => setDataSex(e)"
              >
                <v-radio
                    color="var(--main_color)"
                    :label="locale.trial.male"
                    :value="1"
                />
                <v-radio
                    :label="locale.trial.female"
                    color="var(--main_color)"
                    :value="2"
                    :class="{'ml-auto': isDesktop}"
                />
              </v-radio-group>
            </div>
          </div>
          <v-divider/>
          <div class="trial-info__main--side-content--age">
            <div class="trial-info__main--side-content--age-title">
              {{ locale.trial.age }}
            </div>
            <div class="trial-info__main--side-content--age-select">
              <v-select
                  dense
                  outlined
                  :items="exerciseItem.ageCategories.list"
                  item-text="description"
                  :placeholder="exerciseItem.ageCategories.list[0].description"
                  item-value="id"
                  @change="(item) => chouseAge(item)"
                  v-model="selectedAge"

              ></v-select>
            </div>
          </div>
          <v-divider/>
          <div class="trial-info__main--side-content--result" v-if="items">
            <div class="trial-info__main--side-content--result-title">
              {{ locale.trial.result }}
            </div>
            <div class="trial-info__main--side-content--result-input">
              <label>
                <input type="text" v-model="search" :placeholder="locale.table.placeholderFilter">
              </label>
            </div>
          </div>
          <div class="trial-info__results" v-if="items">
            <div class="results__header">
              <table class="results__table">
                <tr>
                  <th>{{ locale.table.result_tr }}</th>
                  <th>{{ locale.labels.points }}</th>
                </tr>
              </table>
            </div>

            <div class="results__container">
              <table class="results__table results__table--content">
                <tr v-for="item in filteredResults" :key="`results_${item.points}`">
                  <td>
                    {{ item.value }}
                  </td>
                  <td>
                    {{ item.points }}
                  </td>
                </tr>

              </table>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import {api} from '@/api/api';

export default {
  data() {
    return ({
      search: '',
      headersTexts: {
        en: {
          result: '',
          points: ''
        },
        ru: {
          result: 'Результат испытания',
          points: 'Баллы'
        }
      },
      exerciseItem: null,
      row: null,
      items: null,
      item: null,
      selectedAge: null,
      sex: 1,
    })
  },
  watch: {
    festivalInfo: function () {
      this.exerciseItem = this.festivalInfo.exercises.find(x => x.exercise.id === parseInt(this.$route.params.id))

      if (this.exerciseItem) {
        this.selectedAge = this.exerciseItem.ageCategories.list[0].id || null
        this.sex = this.exerciseItem.genders.list[0].id || null

        this.getResults()
      }

    },
  },
  async created() {
    if (this.festivalInfo) {
      this.exerciseItem = this.festivalInfo.exercises.find(x => x.exercise.id === parseInt(this.$route.params.id))

      if (this.exerciseItem) {
        this.selectedAge = this.exerciseItem.ageCategories.list[0].id || null
        this.sex = this.exerciseItem.genders.list[0].id || null

        this.getResults()
      }
    }

  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      festivalInfo: 'desk/festivalInfo',
      locale: 'lang/locale',
      language: 'lang/language',
      isDesktop: 'app/isDesktop',
      isMobile: 'app/isMobile'
    }),
    filteredResults() {
      return this.items.filter(item => item.value.includes(this.search))
    }
  },
  methods: {
    ...mapMutations({
      setExerciseItem: 'trial/setExerciseItem'
    }),
    goBack() {
      this.$router.go(-1)
    },

    async chouseAge() {
      this.getResults()
    },
    getYouTubeId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return (match && match[2].length === 11)
          ? match[2]
          : null;
    },
    async setDataSex() {
      this.getResults()
    },
    async getResults() {
      let response = await api.exercises.get(this.$route.params.slug, this.$route.params.id, this.sex, this.selectedAge);
      this.items = response.data;
    }
  }
}
</script>

<style lang="scss">
.trial-info-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.trial-info {
  margin-top: 80px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1500px;


  .element .v-data-table__wrapper::-webkit-scrollbar {
 margin-top: 32000px !important;
}

  iframe {
    width: 100%;
    height: 480px;
    @media screen and (max-width: 768px) {
      width: 100%;
      height: 315px;
    }
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    .btn-back {

      padding: 10px 21px 11px 21px;

      background: var(--extra_color);
      color: #fff;

      font-size: 16px;
    }
  }

  &__main {
    display: flex;
    margin-top: 34px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    &--content {
      display: flex;
      flex-direction: column;
      flex: 2;


      &-title {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;

        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 30px;

        color: #3C3C3C;

        @media screen and (max-width: 768px) {
          text-align: start;
        }
      }

      &-text {
        margin-bottom: 30px;
        max-width: 790px;

        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;

        text-align: start;

        color: #3C3C3C;
      }
    }

    &--side-content {
      display: flex;
      flex-direction: column;
      margin-left: 34px;
      flex: 1;

      @media screen and (max-width: 768px) {
        margin-left: 0;
      }


      &--sex {
        display: flex;
        flex-direction: column;

        max-width: 80%;


        &-title {
          display: flex;
          justify-content: flex-start;

          font-family: Helvetica;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;

        }
      }

      &--age {
        margin-top: 22px;

        &-title {
          display: flex;
          justify-content: flex-start;

          font-family: Helvetica;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
        }

        &-select {
          margin-top: 15px;
        }
      }

      &--result {
        margin-top: 22px;
        margin-bottom: 27px;

        &-title {
          display: flex;
          justify-content: flex-start;

          font-family: Helvetica;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
        }

        &-input {
          margin-top: 15px;

          label {
            position: relative;

            &::before {
              content: '';
              width: 20px;
              height: 20px;
              position: absolute;
              background: url(../svg/loup.svg) no-repeat center;
              top: 50%;
              right: 15px;
              -webkit-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              transform: translateY(-50%);
            }

            input {
              width: 100%;
              height: 40px;
              outline: 0;
              padding: 0 40px 0 10px;
              border-radius: 5px;
              border: 1px solid #949494;
              font-family: 'Roboto', sans-serif;
              font-size: 14px;
              color: #3c3c3c;
              letter-spacing: .5px;
              transition: border-color .15s ease-in-out;
            }
          }

          @media screen and (max-width: 768px) {
            label {
              input {
                width: 100%
              }
            }
          }
        }
      }

      .v-application .elevation-1 {
        box-shadow: none !important;
      }


    }
  }

  &__results {
    .results {
      &__header {
        border-top: 1px solid var(--extra_color);
        border-bottom: 1px solid var(--extra_color);
      }

      &__table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;

        td, th {
          font-size: 14px;
          font-weight: normal;
          color: #3C3C3C;
          padding: 10px 10px;

          &:first-child {
            width: 70%;
            text-align: left;
          }
        }

        &--content {
          tr:nth-child(odd) {
            background: #EFEFEF;
          }

        }

      }

      &__container {
        height: 405px;
        overflow-x: hidden;
        overflow-y: scroll;

        scrollbar-track-color: var(--extra_color) #fff;
        scrollbar-width: thin;

      }
    }


  }

}
</style>
