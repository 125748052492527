export const en = {
         nav: [
           { id: 1, value: "Main" },
           { id: 2, value: "STANDINGS" },
           { id: 3, value: "COMPETITIONS" },
           { id: 4, value: "FESTIVAL MAP" },
           { id: 5, value: "CONTACT US" },
         ],
          weekdays: [
            'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'
          ], 
          months: [
            'January', 'February', 'March', 'April',
            'May', 'June', 'July', 'August',
            'September', 'October', 'November', 'December'
          ],
         labels: {
           organizers: "Sponsors",
           about: "About festival",
           location: "Where",
           dates: "When",
           share: "Share",
           trial: "Competitions",
           registration: "Registration",
           points: "Points"
         },
         trial: {
           placeholder: "Search by exercise",
           title: "Festival Competitions",
           labels: {
             sex: "Gender",
           },
           male: "Male",
           female: "Female",
           age: "Age",
           result: "Enter your result",
           chouse: "Choose an age level",
           btn_back: "Back",
         },
         auth: {
           registration: "Sign up",
           user_id: "Identification number",
           sex: "Gender",
           team: "Team",
           country: "Country",
           patronymic: "Middle name (optional)",
           date: "Date of Birth",
           name: "First name",
           surname: "Last name",
           photo_t: "Personal photo for accreditation",
           photo_d: ".jpg, .png Maximum size 200x200px",
           fill: "Fill in the fields",
           consent:
             "Consent to participate in the event and the processing of personal data",
           sexSelect: [
             { id: 1, text: "Male", value: "male" },
             { id: 2, text: "Female", value: "female" },
           ],
           error: "Ops!",
           error_des: "Someone had already used the ID you just tried!",
           thanks: "GREAT",
           thanks_des: "You have signed up!",
           error_text: "Required field",
           error_date: "Incorrect date value",
           error_require_date: "Enter the date",
           error_date_modal:
             "The participant's date of birth does not meet the conditions of the festival. The participant must be at least 6 years old for",
         },
         map: {
           logo: "Festival map",
         },
         contacts: {
           logo: "Contacts us",
           phone: "Phone",
           location: "Where",
         },
         table: {
           logo: "Standings",
           compact: "Compact",
           extended: "Extended",
           description: 'The results are displayed by default with a combined or average rating. If it required you can apply filters result display for a definite and other parameters.',
           view: "View",
           type: "Rank",
           individual: "Individual",
           team: "Team",
           rating: "Raiting",
           summary: "Summary",
           average: "Average",
           show: "Show on page:",
           commands: "Teams",
           competition: "Competition",
           placeholderFilter: "Search by result",
           errorTableText: "No filter matches",
           download_XLS: "Download XLS",
           full_mode: "Full Screen mode",
           uin: "UIN",
           name: "Name",
           team_table: "Team",
           date: "Birth date",
           gender: "Gender",
           age: "Age",
           total_summary: "Total summary",
           total_summaryMid: "Total summary (Average)",
           score_summary: "Score summary",
           place: "Place",
           total_average: "Total average",
           score_count: "Score summary / Count of competitions",
           result: "Result",
           score: "Score",
           search: "Search",
           result_tr: "Exercise result",
           search_uin: "Search by name or UIN ",
           search_team: "Search by team",
           loading: "Loading, please wait",
         },
         footer: {
           direction: "ANO Directorate for Sport and Social Projects",
         },
         placeholders: {
           select_exercises: "Select competitions",
           select_teams: "Select team",
         },
         buttons: {
           back: "Back",
           next: "Next",
           registration: "Sign up",
           apply_filter: "Apply filter",
           reset_filter: "reset filter",
           ok: "OK",
           chouse_photo: "Choose file",
           close: "Close",
           save: "Save",
           sellect_all: "Select All",
         },
       };
