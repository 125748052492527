<template>
 <div class="contacts" v-if="festivalInfo">
  <div class="contacts__header">
   <div class="contacts__header--block">
    <div class="background"></div>
   </div>
   <div class="title-block">
    <div class="title-container">
     <div class="contacts__header--title">{{locale.contacts.logo}}</div>
    </div>
   </div>
  </div>
  <div class="contacts__info-container">
   <div class="contacts__info">
   <div class="info-item">
    <div class="info-item__title">{{locale.contacts.phone}}</div>
    <div class="info-item__data">
      <a :href="`tel:${festivalInfo.phone}`">{{festivalInfo.phone}}</a>
    </div>
   </div>
   <div class="info-item">
    <div class="info-item__title">E-mail</div>
    <div class="info-item__data">
      <a :href="`mailto:${festivalInfo.email}`">
        {{festivalInfo.email}}
      </a>
    </div>
   </div>
   <div class="info-item">
    <div class="info-item__title">{{locale.contacts.location}}</div>
    <div class="info-item__data">{{language === 'ru' ? festivalInfo.location.title_ru :festivalInfo.location.title_en}}</div>
   </div>
   </div>
  </div>
  <div class="contacts__main" v-if="festivalInfo.location.coordinates">
   <div class="contacts__main--container">
      <yandex-map 
        :coords="festivalInfo.location.coordinates.split(',')"
        :zoom="10" 
  >
    <ymap-marker 
      :coords="festivalInfo.location.coordinates.split(',')" 
      marker-id="123" 
      :balloon-template="balloonTemplate"
      hint-content="some hint" 
    />
  </yandex-map>
   </div>
  </div>
 </div>
</template>

<script>
import {yandexMap, ymapMarker} from 'vue-yandex-maps'
import { mapGetters} from 'vuex';
export default ({
 components: {yandexMap, ymapMarker},
 
 computed: {
   ...mapGetters({
      festivalInfo: 'desk/festivalInfo',
      locale: 'lang/locale',
      language: 'lang/language',
      isDesktop: 'app/isDesktop',
      isMobile: 'app/isMobile'
    }),
    balloonTemplate() {
      return `
       <h1>${this.festivalInfo.location.coordinates_name ? this.festivalInfo.location.coordinates_name : ''}</h1>
     `
    }
 },
})
</script>

<style lang="scss">
.ymap-container {
  height: 100%;
  width: 100%;
}
.contacts {
 padding-bottom: 100px;
 height: 100%;


 &__header {
  height: 100px;
  margin-bottom: -30px;
  width: 100%;
  position: relative;
  padding-bottom: 30px;
  z-index: 1;

  .title-block {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .title-container {
    width: 100%;
    height: 100%;
    max-width: 1500px;
    position: relative;
  }

  &::before {
   bottom: 30px;
   transform: skewY(0);
   background-color: #8F8F8F;
   content: "";
   position: absolute;
   bottom: 31px;
   left: 0;
   width: 100%;
   height: calc(100% - 34px);
   z-index: -1;
   transform-origin: left;
   transform: skewY(360deg);
  }

  &--block {
   box-shadow: none;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: calc(100% - 30px);
   z-index: -1;
   transform-origin: right;
   transform: skewY(1deg);
   overflow: hidden;

   .background {
    box-shadow: 0 15px 30px rgb(0 0 0 / 20%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: right;
    transform: skewY(360deg);
    background: linear-gradient(to right, var(--gradient_color) 0,var(--main_color) 100%);
    background-size: cover;
    background-position: center;
   
   }
  }

  &--title {
   padding: 10px 30px;

   position: absolute;
   bottom: -22px;
   left: 0px;

   font-size:35px;


   background: var(--main_color);
   color: #fff;

   &::before {
     content: "";

     width: 94%;
     height: 109%;
    
     position: absolute;
     top: -3px;
     left: 6px;
     transform: skewY(-8deg);
     z-index: -1;

     background-color: #dcdce0;
   }
  }
 }

 &__info-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
 }

 &__info {
  margin-top: 60px;
  width: 100%;
  max-width: 1300px;

  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  

  .info-item {
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;

   &:nth-child(2) {
    margin-left: auto;
   }

   &:nth-child(3) {
    margin-left: auto;
   }

   &__title {
    color: var(--main_color);
    font-size: 40px;
    font-weight: 300;
   }

   &__data {
     a {
      font-size: 16px;
      color: #3C3C3C;
      text-decoration: none;
     }
    font-size: 16px;
    color: #3C3C3C;
   }
  }

   @media screen and (max-width: 768px) {
    grid-auto-flow: row;
    grid-template-columns: repeat(1, 1fr);



    .info-item {
      display: flex;
      flex-direction: column;

      &:nth-child(2) {
        margin-left: 0;
        margin-top: 5px;
      }

      &:nth-child(3) {
        margin-left: 0;
        margin-top: 5px;
      }

      &__title {
        color: var(--main_color);
        font-size: 25px;
        font-weight: 300;
      }

      &__data {
        font-size: 12px;
        color: #3C3C3C;
      }
    }
  }
 }

 &__main {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 600px;
  max-height: 50%;
  margin-top: 6%;
  padding: 0px 50px;

  background: #EFEFEF;

  @media screen and (max-width: 768px) {
    align-items: center;
    padding: 0px 0px;
  }

  &--container {
   width: 100%;
   height: 100%;
   min-height: 400px;
   display: flex;
   padding: 20px 0px;
   justify-content: center;
   max-width: 1500px;
   background-size: cover;
  }
 }
}
</style>