<template>
 <div class="desk" v-if="festivalInfo">
   <CircleIcon v-if="isDesktop"/>
   <Ellips v-if="isDesktop"/>
  <div class="desk__header">
   <div class="header-wallpaper">
    <div class="wallper-background" :class="{withoutBack: !festivalInfo.interface.background}" :style="{backgroundImage:festivalInfo.interface.background ? `url(${festivalInfo.interface.background.url})`: ''}">
    </div>
   </div>
   <div class="container-block">
    <div class="content-block">
      <div class="header-organizers" v-if="festivalInfo.interface.organizers.length !== 0">
        <div class="organizers-title">{{locale.labels.organizers}}</div>
        <div class="organizers-logos-container">
          <template v-for="organizer in festivalInfo.interface.organizers">
            <a :href="organizer.link" target="_blank" class="organizers-logo" :key="organizer.id" v-if="organizer.logo">
              <img :src="organizer.logo.url" alt="">
            </a>
          </template>
        </div>
       
      </div>
      <div class="wallpaper-text" ref="wallper_text" :class="[mainTextSize, {'wallpaper-text_has-organizers': festivalInfo.interface.organizers.length}]">
        {{language === 'ru' ? festivalInfo.title_ru : festivalInfo.title_en}}
      </div>
    </div>
   </div>
  </div>
  <div class="desk__orgainzation-mobile" v-if="festivalInfo.interface.organizers.length !== 0">
     <div class="organizers-title">{{locale.labels.organizers}}</div>
     <div class="items_mobile">
        <template v-for="organizer in festivalInfo.interface.organizers">
          <a :href="organizer.link" class="or" :key="organizer.id" v-if="organizer.logo">
            <img :src="organizer.logo.url" alt="">
          </a>
        </template>
     </div>
       
  </div>
  <div class="desk__about-festival">
    <div class="desk__about-festival-container">
      <div class="about-festival-title">
      {{locale.labels.about}}
    </div>
    <div class="content">
      <div class="content-description" v-if="festivalInfo.description_ru || festivalInfo.description_en">
       {{language === 'ru' ? festivalInfo.description_ru : festivalInfo.description_en }}
      </div>
      <div class="content-place-date">
        <div class="content-date">
           <div class="date-icon">
             <v-icon color="var(--extra_color)">mdi-calendar-month</v-icon>
          </div>
          <div class="date-text">
            <p class="label">{{locale.labels.dates}}</p>
            <p class="date">{{festivalInfo.opening_date.split("-").reverse().join(".")}} - {{festivalInfo.ending_date.split("-").reverse().join(".")}}</p>
          </div>
        </div>
        <div class="content-place" v-if="festivalInfo.location.coordinates_name">
          <div class="place-icon">
             <v-icon color="var(--extra_color)">mdi-map-marker</v-icon>
          </div>
          <div class="place-text">
            <p class="label">{{locale.labels.location}}</p>
            <p class="place">{{language === 'ru' ? festivalInfo.location.title_ru : festivalInfo.location.title_en}}</p>
            
          </div>
        </div>
        
      </div>
    </div>
    <v-divider color="#bb9657"></v-divider>
    <div class="desk__socials">
      <p>{{locale.labels.share}}:</p>
      <div class="socials-items">
          <ShareNetwork
            network="vk"
            title=""
            :url="festivalInfo.link"
          >
            <VKSvg/>
          </ShareNetwork>

          <ShareNetwork
            network="telegram"
            title=""
            :url="festivalInfo.link"
          >
           <TelegramSvg/>
          </ShareNetwork>

          <ShareNetwork
            network="twitter"
            title=""
            :url="festivalInfo.link"
          >
           <TwitterSvg/>
          </ShareNetwork>
      </div>
    </div>
    </div>
  </div>
  <div class="desk__partners-container">
    <div class="desk__partners">
    <template v-for="partner in festivalInfo.interface.partners">
      <div class="partner-block" :key="partner.id">
        <div class="container-item grid-block">
          <template v-for="el in partner.logos">
          <a :href="el.link" :key="el.id" v-if="el.logo">
            <img :src="el.logo.url" alt="">
          </a>
        </template>
        </div>
        <div class="partner-block__title">{{language === 'ru' ? partner.title_ru : partner.title_en}}</div>
      </div>
    </template>
  </div>
  </div>
  
 </div>
</template>

<script>
import {mapGetters,mapMutations} from 'vuex';
import VKSvg from '@/views/Desk/svg/VKSvg.vue';
import TelegramSvg from '@/views/Desk/svg/TelegramSvg.vue';
import TwitterSvg from '@/views/Desk/svg/TwitterSvg.vue';
import CircleIcon from '@/views/Desk/svg/CircleIcon.vue';
import Ellips from '@/views/Desk/svg/Ellips.vue';
// import {api} from "@/api/api";

export default ({
 name: 'Desk',
 components: {
  //  InstagramSvg,
   VKSvg,
   TelegramSvg,
   TwitterSvg,
   CircleIcon,
   Ellips,
 },
  computed: {
   ...mapGetters({
      festivalInfo: 'desk/festivalInfo',
      locale: 'lang/locale',
      language: 'lang/language',
      isDesktop: 'app/isDesktop',
      isMobile: 'app/isMobile'
    }),
    mainTextSize() {
      const str =  this.language === 'ru' ? this.festivalInfo.title_ru.length : this.festivalInfo.title_en.length;

      if(str > 360) {
        return 'long-3x'
      } else if(str > 240) {
        return 'long-2x'
      } else if(str > 120) {
        return 'long'
      }

      return ''
    }
  },
  methods: {
    ...mapMutations({
      setFestivalInfo: 'desk/setFestivalInfo',
      setCountries: 'desk/setCountries'
    }),
  }
})
</script>

<style lang="scss" scoped>

 .instagram {
  cursor: pointer;
  background: #D8D8D8;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font: {
    size: 25px;
  };
  &:hover {
    background: -webkit-radial-gradient(33% 100% circle, #FED373 4%, #F15245 30%, #D92E7F 62%, #9B36B7 85%, #515ECF);
    background: radial-gradient(circle at 33% 100%, #FED373 4%, #F15245 30%, #D92E7F 62%, #9B36B7 85%, #515ECF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.desk {
  height: 100%;
  width: 100%;
  position: relative;
  padding-bottom: 150px;

  &__orgainzation-mobile {
    width: 100%;
    display: flex;
    position: relative;
    z-index: 15;

    .items_mobile {
      width: 100%;
      display: grid;
      grid-auto-flow: column;
      gap: 60px;
      padding-left: 34%;
      align-items: center;
      
      height: 220px;

      overflow-x: scroll;

      box-shadow: 0px 15px 30px #00000033;

      a {
        width: 130px;
        height: 130px;
        background-size: cover;

        display: flex;
        align-items: center;

        img {
          width: 130px;
          height: 130px;
        }
      }
    }

    .organizers-title {
      padding: 11px 14px 11px  15px;

      position: absolute;
      top: -35px;
      left: 10px;

      background: var(--main_color);
      color: #fff;

      font-size: 20px;

      &::before {
        content: "";

        width: 94%;
        height: 89%;
       
        position: absolute;
        top: 1px;
        left: 6px;
        transform: skewY(-8deg);
        z-index: -1;

         background-color: #dcdce0;
      }
    }

    @media screen and (min-width: 1030px) {
      display: none;
    }
  }

  .circle-svg {
    width: 712px;
    height: 712px;
    position: absolute;
    top: 25%;
    right: -356px;
    z-index: 0;
    fill: #efefef;

    @media screen and (max-width:1030px) {
      display: none;
    }
  }

  .ellips-svg {
    width: 597px;
    height: 609px;
    position: absolute;
    top: 40%;
    left: -12%;
    transform: rotate(356deg);
    z-index: 0;

    @media screen and (max-width:1030px) {
      display: none;
    }
  }
  
 &__header {
  height: 573px;

  transform-origin: right;

  position: relative;
  top: 0;
  left: 0;

  @media screen and (max-width: 1030px){
    height: auto;
  }
  
  .container-block {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .content-block {
      width: 100%;
      max-width: 1500px;
      height: 100%;

      position: relative; 
    }
  }
  .header-wallpaper {
   position: relative;

   @media screen and (max-width: 1030px) {
      position: absolute;
      left: 0;
      right:0;
      bottom:0;
      top:0;
    }
   .wallper-background {
    height: 573px;

    transform-origin: right;

    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    transform: skewY(4deg);
    z-index: 2;

    background-position: center;
    background-size: 100%;

    @media screen and (max-width: 1030px) {
      transform: none;
      background-size: 100% 100%;
      bottom: 0;
      height: auto;
    }

    &.withoutBack {
      &::before {
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        position: absolute;
        background: linear-gradient(225.71deg, var(--gradient_color) 2.69%, var(--main_color) 100%);;
        box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.2);
      }
    }
    
   }

   &::before {
    content: "";
    
    width: 100%;
    height: 550px;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transform-origin: left;
    transform: skewY(358deg);

    background-color: #dfdfe3;

    @media screen and (max-width: 1030px) {
      content: none;
    }
   }
  }

  .header-organizers {
    background: #fff;
    box-shadow: 0px 15px 30px #00000033;
    position: absolute;
    top: 80%;
    right: 0;
    z-index: 20;

    .organizers-logos-container {
        display: grid;
        grid-auto-flow: column;
        gap: 76px;
        padding: 0 62px;
        align-items: center;
        height: 200px;
        z-index: 20;
    }

    .organizers-title {
      padding: 13px 31px 12px  22px;

      position: absolute;
      top: -60px;
      left: -10px;

      background: var(--main_color);
      color: #fff;

      font-size: 27px;

      &::before {
        content: "";

        width: 94%;
        height: 89%;

        position: absolute;
        top: 1px;
        left: 6px;
        transform: skewY(-8deg);
        z-index: -1;

        background-color: #dcdce0;
      }
    }

    .organizers-logo {
      display: block;
      width: 120px;
      height: 120px;

      cursor: pointer;

      img {
        width: 100%;
        height: 100%;

      }
    }

    @media screen and (max-width: 1030px) {
      display: none;
    }
  }

  .wallpaper-text {
    max-width: 920px;
    position: absolute;
    top: 35px;
    left: 0;
    z-index: 2;
    font-size: 45px;
    font-weight: 500;
    text-align: start;
    color: #FFFFFF;

    &.long {
      font-size: 35px;
    }
    &.long-2x {
      font-size: 32px;
    }
    &.long-3x {
      font-size: 28px;
    }

    @media screen and (max-width: 1030px) {
      min-height: 330px;
      position: relative;
      top: 0;
      padding: 35px 0;
      max-width: 100%;
      font-size: 25px;
      &_has-organizers {
        padding: 35px 0 70px 0;
      }
    }
  }
 }

 &__about-festival {
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 170px;
   
   position: relative;
   z-index: 11;

   @media screen and (max-width: 1030px) {
     padding: 0 10px;
     margin-top: 150px;
   }

   &-container {
     max-width: 1500px;
     width: 100%;
     position: relative;
   }

   .about-festival-title {
      padding: 16px 22px 16px 28px;

      position: absolute;
      top: -80px;
      left: 0;
      

      background: var(--main_color);
      color: #fff;

      font-size:35px;

      &::before {
        content: "";

        width: 94%;
        height: 89%;
       
        position: absolute;
        top: 1px;
        left: 6px;
        transform: skewY(-8deg);
        z-index: -1;

        background-color: #dcdce0;

        @media screen and (max-width: 1030px) {
          height: 97%;
        }
      }

    @media screen and (max-width: 1030px) {
     font-size: 20px;
     padding: 12px 9px 11px 15px;
     top: -65px;
    }
   }

   .content {
     width: 100%;
     display: flex;
     margin: 20px 0;
     justify-content: space-between;

     .content-description {
      width: 100%;
      flex: 1;

      text-align: start;
      font-size: 16px;

      column-count: 2;
     }

     .content-place-date {
       display: flex;
       flex-direction: column;
       flex: 0.4;
       align-items: flex-start;
       margin-left: 82px;

       .content-date {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;

        .date-text {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

           .label {
             margin-bottom: 10px;

             font-size: 16px;
           }

           .date {
             font-size: 20px;
             font-weight: bold;

             color: var(--main_color);

           }
         }

         @media screen and (max-width: 1030px) {
          justify-content: flex-start;
          margin-left: 0px;

          .date-text {

            .label {
              font-size: 14px
            }

            .date {
              font-size: 18px
            }
            
          }
        }
       }

       .content-place {
         display: flex;
         align-items: flex-start;

         .place-text {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

            .label {
             margin-bottom: 10px;

             font-size: 16px
            }

            .place {
             font-size: 20px;
             font-weight: bold;

             color: var(--main_color);

             text-align: start;
            }
         }

        @media screen and (max-width: 1030px) {
          justify-content: flex-start;

          .place-text {

            .label {
              font-size: 14px
            }

            .place {
              font-size: 18px;
            }
            
          }
        }
       }
     }

      @media screen and (max-width: 1030px) {
      flex-direction: column;
      margin: 0px 0px 20px 0px;

      .content-description {
        margin: 20px 0px;
        column-count: 1;
      }

      .content-place-date {
        margin-left: 0;
      }

     }
     
   }
 }

 &__socials {
   display: flex;
   align-items: flex-start;
   margin-top: 20px;
   padding: 0 20px;

   p {
     font-size: 16px;
     color: #949494;
   }

   .socials-items {
    display: flex;
    align-items: center;
   }

   a {
     margin-left: 10px;
     color: white;
   }

   @media screen and (max-width: 1030px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      width: 100%;
      text-align: center;
    }

    a {
     margin-left: 0px;
     color: white;
    }
    .socials-items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      overflow-x: scroll;

      .social-inst {
        display: flex;
        align-items: center;
      }
    }
     
   }
 }

 &__partners-container {
   display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
 }

 &__partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  padding: 0 20px;
  width: 100%;
  max-width: 1500px;

  @media screen and (max-width: 1030px) {
    padding: 0px;
  }
   

   .partner-block {
     width: 100%;
     min-height: 500px;
     display: flex;
     align-items: center;
     margin-top: 200px;

     position: relative;
     z-index: 22;

     @media screen and (max-width: 1030px){
      min-height: 200px;
      margin-top: 80px;

      .partner-block__title {
        top: -5%;
      }
     }

    &:nth-child(1) {
      margin-top: 0px;
     
    }

    @keyframes morph {
      0% {transform: rotateX(-7deg) rotateY(0) translateX(-50px)}

      25% {transform: rotateX(7deg) rotateY(7deg)}
        
      50% {  transform: rotateX(-7deg) rotateY(7deg)}
      
      75% {transform: rotateX(-7deg) rotateY(-7deg) }

      100% {transform: rotateX(-7deg) rotateY(0) translateX(50px)}
        
    }

    
      .container-item {
        width: 100%;
        min-height: 500px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 50px;
        padding: 140px 100px 100px 80px;
        z-index: -1;

        position: relative;

        &::before {
          content: "";

          background: #EFEFEF;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0%;
          right: 0%;
          z-index: -1;

          clip-path: polygon(0% 7%, 96% 0%, 100% 0%, 96% 100%, 4% 100%);
          animation: morph ease-in-out 20s infinite alternate;


          @media screen and (mix-width: 1600px) {
            left: -10%;
            right: -10%;
          }
        }

        @media screen and (max-width: 1030px) {
          display: flex;
          width: 100%;
          height: 200px;
          min-height: 200px;
          align-items: center;
          overflow-y: hidden;
          padding: 100px 100px 100px 80px;

          overflow-x: scroll;

          background: #EFEFEF;

          clip-path: none;

          &::before {
            animation: none;
            content: none;
          }

          a {
            img {
              width: 70px;
              height: 70px;
            }
          }
        }
      }

    .partner-block__title {
      padding: 13px  26px 12px 30px;

      position: absolute;
      top: 0px;
      left: 0px;

      background: var(--main_color);
      color: #fff;

      font-size:35px;

      &::before {
        content: "";

        width: 94%;
        height: 89%;
       
        position: absolute;
        top: 1px;
        left: 6px;
        transform: skewY(-8deg);
        z-index: -1;

        background-color: #DCDCE0;
      }

      @media screen and (max-width: 1030px) {
        padding: 12px 10px 10px 15px;
        top: -20px;
        left: 15px;

        font-size: 20px;
        

        &::before {
          height: 100%;
          width: 107%;
          left: -7px;
        }
      }
    }

    a {
      width: 200px;
      height: 200px;
      padding: 30px;
      justify-self: center;

      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 15px 30px #00000040;
      background-size: cover;
      border-radius: 50%;

      @media screen and (max-width: 1030px) {
        width: 130px;
        height: 130px;

        margin-left: 30px;

        &:nth-child(1) {
          margin-left: 0px;
        }
      }
      

      img {
        width: 100%;
        height: 100%;      
      }
    }
   }
 }
}
</style>
