import Vue from "vue";
import Vuex from "vuex";
import desk from "@/store/desk";
import lang from "@/store/lang";
import app from "@/store/app";
import trial from "@/store/trial";
Vue.use(Vuex);

const namespaced = true;
export default new Vuex.Store({
  namespaced,
  state: {},
  mutations: {},
  actions: {},
  modules: {
    desk,
    lang,
    app,
    trial,
  },
});
