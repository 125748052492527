export const ru = {
         nav: [
           { id: 1, value: "Главная" },
           { id: 2, value: "Турнирная таблица" },
           { id: 3, value: "Испытания" },
           { id: 4, value: "Карта фестиваля" },
           { id: 5, value: "Контакты" },
         ],
          weekdays: [
            'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс',
          ],
          months: [
            'Январь', 'Февраль', 'Март', 'Апрель',
            'Май', 'Июнь', 'Июль', 'Август',
            'Сентябрь', 'Остябрь', 'Ноябрь', 'Декабрь'
          ],
         labels: {
           organizers: "Организаторы",
           about: "О фестивале",
           location: "Место проведения",
           dates: "Дата проведения",
           share: "Рассказать о фестивале",
           trial: "Испытания",
           registration: "Регистрация",
           points: "Баллы"
         },
         trial: {
           placeholder: "Поиск по испытанию",
           title: "Испытания фестиваля",
           labels: {
             sex: "Пол",
           },
           male: "Мужской",
           female: "Женский",
           age: "Возрастная ступень",
           result: "Ваш результат",
           chouse: "Выберите возрастную ступень",
           btn_back: "Назад",
         },
         auth: {
           registration: "Регистрация",
           user_id: "Идентификационный номер участника",
           sex: "Пол",
           team: "Команда",
           fill: "Заполните поля",
           country: "Страна",
           patronymic: "Отчество(не обязательно)",
           date: "Дата рождения",
           name: "Имя",
           surname: "Фамилия",
           photo_t: "Фотография для аккредитации",
           photo_d: ".jpg, .png Максимальный размер 200x200px",
           consent:
             "Согласие на участие в мероприятии и обработку персональных данных",
           sexSelect: [
             { id: 1, text: "Мужской", value: "male" },
             { id: 2, text: "Женский", value: "female" },
           ],
           error: "Ошибка!",
           error_des: "На введенный ID кто-то уже записался!",
           thanks: "СПАСИБО!",
           thanks_des: "Вы зарегистрированы!",
           error_text: "Обязательное поле",
           error_date: "Некорректное значение даты",
           error_require_date: "Введите дату",
           error_date_modal:
             "Дата рождения участника не удовлетворяет условиям проведения фестиваля. Участнику должно быть не менее 6 лет на",
         },
         map: {
           logo: "Карта фестиваля",
         },
         table: {
           logo: "Турнирная таблица",
           view: "Вид",
           compact: "Компактный",
           extended: "Расширенный",
           description: 'Результаты по умолчанию показываются суммарным и средним рейтингом. Если необходимо, вы можете применить в фильтрах отображения результата по конкретному испытанию и другим параметрам.',
           type: "Тип зачета",
           individual: "ЛИЧНЫЙ",
           team: "Команда",
           rating: "Тип рейтинга",
           summary: "Суммарный",
           average: "Средний",
           show: "Показывать на странице:",
           commands: "Команды",
           competition: "Вид испытания",
           placeholderFilter: "Поиск по результату",
           errorTableText: "Нет совпадений по фильтрам",
           download_XLS: "Скачать в XLS",
           full_mode: "Полноэкранный режим:",
           uin: "УИН",
           name: "ФИО",
           team_table: "Команда",
           date: "Дата рожд.",
           gender: "Пол",
           age: "Возрастная ступень",
           total_summary: "Общий итог (Сумма)",
           total_summaryMid: 'Общий итог (Средний)',

           score_summary: "Сумма баллов",
           place: "Место",
           total_average: "Общий итог (Средний)",
           score_count: " Сумма баллов / кол-во испытаний",
           result: "Результат",
           score: "Баллы",
           search: "Поиск",
           result_tr: "Результат испытания",
           search_uin: "Поиск по ФИО или УИН",
           search_team: "Поиск по команде",
           loading: "Пожалуйста подождите, идет загрузка таблицы",
         },
         contacts: {
           logo: "Контакты",
           location: "Место проведения",
           phone: "Телефон",
         },
         footer: {
           direction:
               'АНО "Дирекция спортивных и социальных проектов"',
         },
         placeholders: {
           select_exercises: "Выбрать испытания",
           select_teams: "Выбрать команду",
         },
         buttons: {
           back: "Назад",
           next: "Далее",
           registration: "Регистрация",
           reset_filter: "сбросить фильтр",
           apply_filter: "Применить фильтр",
           ok: "Хорошо",
           chouse_photo: "Выберите файл",
           close: "Закрыть",
           save: "Сохранить",
           sellect_all: "Выбрать все",
         },
       };
