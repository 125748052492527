<template>
 <footer class="footer" :class="{mobile: isMobile}">
  <div class="container-for-shadow">
   <div class="default-block" :class="{mobile: isMobile}">
    <div class="support" :class="{mobile: isMobile}">
     <a href="http://government.ru/." class="support-el">
      <img src="@/assets/images/support-1.png" alt="">
     </a>
     <a href="https://minsport.gov.ru/." class="support-el">
      <img src="@/assets/images/support-2.png" alt="">
     </a>
    </div>
    <div class="copyright">
     <a href="https://www.gto.ru" class="href">www.gto.ru</a>
     <p class="text">
       {{locale.footer.direction}}      
     </p>
    </div>
    <div class="socials" :class="{mobile: isMobile}">
     <a 
        href="https://vk.com/vfsk_gto" 
        class="socials__el"
      >
         <v-icon color="#EFEFEF">mdi-vk</v-icon>
      </a>
      <a 
        href="https://www.youtube.com/vfskgtorussia" 
        class="socials__el"
      >
          <v-icon color="#EFEFEF">mdi-youtube</v-icon>
      </a>
    </div>
   </div>
  </div>

 </footer>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
    
 name: 'Footer',
 computed: {
   ...mapGetters({
      locale: 'lang/locale',
      isDesktop: 'app/isDesktop',
      isMobile: 'app/isMobile'
    }),
  },
}
</script>

<style lang="scss">
.footer {
 width: 100%;
 height: 150px;

 position: relative;
 
 z-index: 7;

 background-color: #efefef;

 @media screen and (max-width: 1030px) {
   height: 40%;
   max-height: 300px;
 }

 &::before {
  content: "";

  position: absolute;
  top: -70px;
  left: 0;

  width: 100%;
  height: 70px;

  pointer-events: none;

  background: url('../../assets/svg/footer.svg');
 }

 .container-for-shadow {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  position: relative;

  box-shadow: 0 -15px 30px rgb(0 0 0 / 20%);

  .default-block {
   height: 100%;
   width: 100%;
   max-width: 1500px;
   padding: 0 20px;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: space-between;
   align-items: center;

    @media screen and (max-width: 1030px) {
      padding: 20px 0;
    }

   .support {
    padding-right: 77px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1030px) {
      padding-right: 0;
      margin-bottom: 20px;
      width: 100%;
    }

    &-el {
     width: 64px;
     height: 70px;

     transition: opacity .15s ease-in-out;

     &:first-child {
      margin-right: 50px;
     }
    }
   }

   .copyright {
    width: 460px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .href {
     margin-bottom: 10px;

     position: relative;

     font-size: 14px;
     font-weight: 500;
     cursor: pointer;
    

     color: #bb9657;
     text-decoration: none;

     &:hover::before {
      transform: scaleX(1);
      transform-origin: left;
     }

     &::before {
      content: "";

      width: 100%;
      height: 2px;

      position: absolute;
      bottom: -3px;
      left: 0;
      transform: scaleX(0);
      transform-origin: right;
      transition: transform .15s ease-in-out;

      background-color: #bb9657;
     }
    }

    .text {
     font-size: 12px;
     line-height: 16px;
     letter-spacing: .2px;

     @media screen and (max-width: 1030px) {
       font-size: 10px;
     }
    }

    @media screen and (max-width: 1030px) {
      width: 100%;
    }
   }

   .socials {
    display: flex;
    justify-content: center;
    align-items: center;


    @media screen and (max-width: 1030px) {
      width: 100%;
      margin-top: 20px;
    }

    a {
     color: #8f8f8f;
    }

    &__el {
    width: 35px;
    height: 35px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    background-color: #8f8f8f;
    border-radius: 50%;
    
    transition: background-color .15s ease-in-out;

    &:not(:last-child) {
     margin-right: 20px;
    }

    &:hover {
      &:nth-child(1) {
        background: #5A7DA3;
        color: #5A7DA3;
      }

      &:nth-child(2) {
        background: #DF2D29;
        color: #DF2D29;
      }
      
      &:nth-child(3) {
        background: #B0368E;
        color: #B0368E;
      }
    }
    }
   }
  }
 }
}
</style>