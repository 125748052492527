<template>
  <div class="agree-rus">
   <div class="agree-rus__participation">
    <h1>СОГЛАШЕНИЕ ОБ УЧАСТИИ В МЕРОПРИЯТИИ {{name}}</h1>
    <p>Настоящим я, {{userSurname}} {{userName}} {{patronymicUser}}, добровольно соглашаюсь на участие в мероприятии {{name}}, место проведения {{ namePlace }}, подтверждаю, что не имею ограничений и противопоказаний для занятий физической культурой и спортом, способен адекватно воспринимать информацию о технике безопасности, соблюдать личные и социальные нормы, правила поведения, контролировать безопасность своей жизнедеятельности. В полной мере обладая свободой волеизъявления, находясь в состоянии, позволяющем полностью осознавать и адекватно оценивать уровень своей физической формы, степень владения приемами и навыками той спортивной дисциплины, в которых планирую выступать при участии в мероприятии, подписывая настоящее Соглашение, подтверждаю, что обладаю достаточной подготовкой к выступлению, позволяющей исключить случайное причинение вреда собственным здоровью и жизни.</p>
    <br>
    <p>При этом я понимаю и осознаю, что опасность и возможность риска при участии в мероприятии включают в себя (но не ограничивается) тяжелые травмы частей тела, которые, в свою очередь, могут вызвать частичную или полную потерю трудоспособности, возможный постоянный паралич и смерть; кроме того, может быть нанесен ущерб общему состоянию моего здоровья и благосостоянию, а также что несоблюдение мной правил техники безопасности может привести к причинению вреда моему здоровью. Никакой объем разумного наблюдения за участниками, а также обучения или защитной экипировки не может исключить всех рисков и опасностей.</p>
    <br>
    <p>Принимая во внимание опасность участия в мероприятии, я осознаю всю важность следования инструкциям спортивного инструктора в отношении техники участия в мероприятии, а также другим правилам и соглашаюсь следовать данным инструкциям.</p>
    <br>
    <p>Если во время моего присутствия или участия в мероприятии я обнаружу угрозу моим жизни и здоровью, обязуюсь прекратить свое участие в таком мероприятии и незамедлительно довести информацию о выявленной угрозе до сведения представителей организационного комитета мероприятия, находящегося в ближайшей доступности.</p>
    <br>
    <p>Я подтверждаю, что представители организационного комитета мероприятия поставили меня в известность, предупредили и предостерегли относительно того, что я могу получить серьезную травму, быть парализован или погибнуть при участии в мероприятии.</p>
    <br>
    <p>Принимая участие в мероприятии, данным соглашением я принимаю на себя все риски, связанные с участием в этом мероприятии, и освобождаю АНО «Дирекция спортивных и социальных проектов» (далее – «Дирекция», юридический адрес: 420111, г.Казань, ул.Баумана 52/7), ее сотрудников или представителей, от любой ответственности (в полной мере, разрешённой законом) и всех (любых) исков, оплаты счетов, компенсаций, и правопритязаний или каких-либо требований, которые могут появиться в связи с моим участием в мероприятии. Данные условия соглашения служат принятием на себя рисков и отказом от всех настоящих и будущих притязаний моих наследников, исполнителей завещания, администратора наследства, правопреемников и всех членов моей семьи, близких родственников, а также притязаний в отношении собственности.</p>
    <br>
    <p>Также, настоящим выражаю свое согласие принять на себя всю ответственность за возможные риски, связанные с повреждением моего имущества, во время проведения тестовых испытаний комплекса ГТО.</p>
    <br>
    <p>Также, настоящим выражаю свое согласие принять на себя всю ответственность за возможные риски, связанные с повреждением моего имущества, во время проведения мероприятия.</p>
    <br>
    <p>Настоящим я даю согласие представителям организационного комитета на фотосъемку и запись моего голоса и выступлений, а также использование моих фотографий, силуэтных изображений и других материалов, предполагающих воспроизведение моего внешнего облика и голоса, для маркетинговых целей в рамках данного мероприятия и мероприятий, которые будут проводиться в последующие годы.</p>
    <br>
    <p>Я признаю, что может проводиться аудио-, видео-, кино- и/или фотосъемка моего выступления, и, давая согласие на фотосъемку и/или видеосъемку, я безвозмездно передаю права, перечисленные в выше абзаце, Дирекции.</p>
    <br>
    <p>С правилами проведения мероприятия ознакомлен(а).</p>
    <br>
    <p>Я понимаю и осознаю важность предоставленной выше информации и предупреждения, подписываю настоящее соглашение добровольно и без какого-либо принуждения. Я осознаю, что проставление знака «V» означает мое письменное согласие с условиями, описанными выше.</p>
    <br>
    <h1>СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ</h1>
    <p>Настоящим я, далее – «Субъект Персональных Данных», во исполнение требований Федерального закона от 27.07.2006 г. № 152-ФЗ «О персональных данных» свободно, своей волей и в своем интересе даю свое согласие АНО «Исполнительная дирекция спортивных проектов» (далее – «Дирекция», юридический адрес: 420111, г.Казань, ул.Баумана 52/7, на обработку своих персональных данных, указанных при регистрации на мероприятие {{name}}.</p>
    <br>
    <p>Под персональными данными я понимаю любую информацию, относящуюся ко мне как к Субъекту Персональных Данных, в том числе мои фамилию, имя, отчество, пол, дату рождения, адрес регистрации по месту жительства, адрес фактического проживания, контактные телефон(ы), адрес электронной почты, паспортные данные, ИНН, страховой номер индивидуального лицевого счета в Пенсионном фонде России (СНИЛС), данные о состоянии моего здоровья, месте учебы (работы) и иную другую информацию. Под обработкой персональных данных я понимаю сбор, систематизацию, накопление, уточнение, обновление, изменение, использование, распространение, передачу, обезличивание, блокирование, уничтожение, бессрочное хранение), и любые другие действия (операции) с персональными данными.</p>
    <br>
    <p>Обработка персональных данных Субъекта Персональных Данных осуществляется исключительно в целях регистрации Субъекта Персональных Данных в системе для участия в мероприятии.</p>
    <br>
    <p>Обработка персональных данных Субъекта Персональных Данных может осуществляться с помощью средств автоматизации и/или без использования средств автоматизации в соответствии с действующим законодательством РФ и внутренними положениями Дирекции.</p>
    <br>
    <p>Дирекция принимает необходимые правовые, организационные и технические меры или обеспечивает их принятие для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных, а также принимает на себя обязательство сохранения конфиденциальности персональных данных Субъекта Персональных Данных. Дирекция вправе привлекать для обработки персональных данных Субъекта Персональных Данных субподрядчиков, а также вправе передавать персональные данные для обработки своим аффилированным лицам или третьим лицам, обеспечивая при этом принятие такими субподрядчиками и аффилированными лицами соответствующих обязательств в части конфиденциальности персональных данных.</p>
    <br>
    <p>Я ознакомлен(а), что:</p>
    <p>1. настоящее согласие на обработку моих персональных данных, указанных при регистрации на мероприятие, действует в течение 50 (пятидесяти) лет с момента такой регистрации;</p>
    <p>2. согласие может быть отозвано мною на основании письменного заявления в произвольной форме;</p>
    <p>3. предоставление персональных данных третьих лиц без их согласия влечет ответственность в соответствии с действующим законодательством Российской Федерации.</p>
    <br>
    <p>Я осознаю, что проставление знака «V» в поле означает мое письменное согласие с условиями, описанными выше.</p>
   </div>
  </div>
</template>


<script>
export default ({
  props: {
   name: {
    required: true
   },
   userName: {
     required: true
   },
   userSurname: {
     required: true
   },
   patronymicUser: {
     required: true
   },
   namePlace: {
     required: true
   }
  }
})
</script>

<style lang="scss">
.agree-rus {
  width: 1000px;
  max-height: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
  padding: 50px;
  position: relative;

  h1 {
   text-align: center;
   margin-bottom: 20px;
  }

  p {
   text-align: start;
  }

  @media screen and (max-width: 768px) {
    padding: 0 20px;
    min-width: 200px;
    min-height: 200px;
    width: 100%;
    height: 100%;
  }

  &__title {
    margin-top: auto;
    width: 100%;
    font-size: 42px;
    font-weight: bold;
  }

  &__description {
    width: 100%;

    font-size: 28px;
    font-weight: 300;
  }

  &__action {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;

    button {
    padding: 5px 18px; 

    background: var(--extra_color);

    color: #FFFFFF
    }
  }
  
}
</style>