import { ru } from "@/constants/lang/ru";
import { en } from "@/constants/lang/en";

export default {
  namespaced: true,
  state: {
    festival: null,
    loading: true,
    app: {
      language: "ru",
    },
  },
  getters: {
    language: (state) => {
      return state.app.language;
    },
    locale: (state) => (state.app.language === "ru" ? ru : en),
  },
  mutations: {
    CHANGE_LANGUAGE(state, payload) {
      state.app.language = payload;
    },
    SET_FESTIVAL(state, payload) {
      state.festival = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
  },
  actions: {
    changeLanguage({ commit }, payload) {
      commit("CHANGE_LANGUAGE", payload);
    },
    setFestival({ commit }, payload) {
      commit("SET_FESTIVAL", payload);
    },
    setLoading({ commit }, payload) {
      commit("SET_LOADING", payload);
    },
  },
};
