<template>

  <div class="rating" :style="{'--bgColor': mainColor}">
    <div class="rating__content">
      <div class="rating__container" v-if="festival && screen">
        <div class="rating__header">
          <div>
            <div class="rating__header--title">Рейтинг участников</div>
          </div>
          <div class="rating__header--age-sex">
            <div class="age-sex-container" v-if="showRu">
              <div class="sex-text">{{ genderTitle('ru') }}</div>
              <div class="age-text">
                Возраст {{ ages }}
              </div>
            </div>

            <div class="age-sex-container" v-if="showEn">
              <div class="sex-text">{{ genderTitle('en') }}</div>
              <div class="age-text">
                Age range {{ ages }}
              </div>
            </div>
          </div>
        </div>
        <div class="rating__description">
          <div v-if="!hideExercisesList">

            <div v-for="exercise in screen.exercises" :key="exercise.id">
              <div class="rating__description--title">
                {{ showRu ? exercise.title_ru : exercise.title_en }}
              </div>
              <div class="rating__description--title en" v-if="showEn && showRu">
                {{ exercise.title_en }}
              </div>

            </div>

          </div>
        </div>


        <div class="rating__table tablescroll">
          <div class="table-header" :class="{ 'table-header--with-result': (screen.exercises.length === 1) }">
            <div class="place">
              <div class="en" v-if="showEn && showRu">Position</div>
              {{ showRu ? 'Место' : 'Position' }}

            </div>
            <div class="name">
              <div class="en" v-if="showEn && showRu"> {{
                  screen.credit_type === 'personal' ? 'Fullname' : 'Team name'
                }}
              </div>
              {{
                showRu ? screen.credit_type === 'personal' ? 'Фио' : 'Название команды' : screen.credit_type === 'personal' ? 'Fullname' : 'Team name'
              }}
            </div>
            <div class="date">
              <div v-if="screen.credit_type === 'personal'">
                <div class="en" v-if="showEn && showRu">Date of Birth</div>
                {{ showRu ? 'Дата рождения' : 'Date of Birth' }}
              </div>

            </div>
            <div class="result" v-if="screen.exercises.length === 1">
              <div class="en" v-if="showEn && showRu">Result</div>
              {{ showRu ? 'Результат' : 'Result' }}

            </div>
            <div class="goals">
              <div class="en" v-if="showEn && showRu">Points</div>
              {{ showRu ? 'Баллы' : 'Points' }}

            </div>
          </div>
          <div class="table-items" :style="{maxHeight: screen.display_members_count > 10 ? '600px' : '100%'}" id="data">
            <template v-if="screen.display_members_count > 10">
              <scroller :data="array" class="scroller-wrap" :option="{speed: .6}">
                <template slot-scope="props">
                  <div :key="props.item.id" class="table-item"
                       :class="{ 'table-item--with-result': (screen.exercises.length === 1),  gold: props.item.place === 1, silver: props.item.place === 2, bronze: props.item.place === 3 }">
                    <div class="table-item__place">
                      {{ props.item.place }}
                    </div>
                    <div class="table-item__name">
                      <div class="name-text"> {{
                          screen.credit_type === 'personal' ? props.item.member.surname : props.item.team.teamable.name
                        }}
                      </div>
                      <div class="surname-text" v-if="screen.credit_type === 'personal'">{{ props.item.member.name }}
                        {{ props.item.member.patronymic }}
                      </div>
                    </div>
                    <div class="table-item__date">
                      <div v-if="screen.credit_type === 'personal'">
                        {{ props.item.member.birthday|formatDate }}
                      </div>
                    </div>
                    <div class="table-item__result" v-if="screen.exercises.length === 1">
                      {{ getResult(props.item) }}
                    </div>
                    <div class="table-item__goals">
                      {{ (screen.rating === 'summary') ? props.item.points_sum : props.item.average_sum }}
                    </div>
                  </div>
                </template>
              </scroller>
            </template>
            <div v-else v-for="item in list.data" :key="item.id" class="table-item"
                 :class="{ 'table-item--with-result': (screen.exercises.length === 1),  gold: item.place === 1, silver: item.place === 2, bronze: item.place === 3 }">
              <div class="table-item__place">
                {{ item.place }}
              </div>
              <div class="table-item__name">
                <div class="name-text"> {{
                    screen.credit_type === 'personal' ? item.member.surname : item.team.teamable.name
                  }}
                </div>
                <div class="surname-text" v-if="screen.credit_type === 'personal'">{{ item.member.name }}
                  {{ item.member.patronymic }}
                </div>
              </div>
              <div class="table-item__date">
                <div v-if="screen.credit_type === 'personal'">
                  {{ item.member.birthday|formatDate }}
                </div>
              </div>
              <div class="table-item__result" v-if="screen.exercises.length === 1">
                {{ getResult(item) }}
              </div>
              <div class="table-item__goals">
                {{ (screen.rating === 'summary') ? item.points_sum : item.average_sum }}
              </div>
            </div>
          </div>
        </div>


        <div class="rating__pagination">
          <div v-for="i in list.last_page" :key="i" :class="{ active: currentPage === i}" @click="currentPage = i"></div>
        </div>
      </div>
      <div class="rating__qr" v-if="screen">
        <div class="rating__qr-container">
          <div class="rating__qr--title">
            Турнирная таблица <br> испытания
          </div>
          <v-divider :color="mainColor"/>
          <div class="rating__qr--icon">
            <vue-qr
                :colorDark="extraColor"
                :text="getLinksForQr"
                backgroundColor="#fff"/>
          </div>
        </div>
      </div>
    </div>
    <div class="rating__bg" :style="{'--circleColor': gradientColor}"></div>
  </div>
</template>


<script>
import {api} from './../../api/api';
import {mapGetters} from 'vuex';

export default ({
  data() {
    return {
      festival: null,
      screen: null,
      list: [],
      currentPage: 1,
      my_time: null,
      count: 0,
      array: [],
      controller: null,
      timer: null
    }
  },
  mounted() {
    this.fetch()

  },
  computed: {
    ...mapGetters({
      language: 'lang/language',
    }),
    showRu() {
      return this.festival && this.festival.languages.filter(item => item.short_name === 'ru').length > 0
    },
    showEn() {
      return this.festival && this.festival.languages.filter(item => item.short_name === 'en').length > 0
    },
    hideExercisesList() {
      return this.screen.exercises.length === this.festival.exercises.length
    },
    ages() {
      let min = Math.min.apply(null, this.screen.age_categories.map(item => item.from));
      let max = Math.max.apply(null, this.screen.age_categories.map(item => item.to));

      return `${min} -${max}`

    },
    getLinksForQr() {
      if (this.festival) {
        return `${this.festival.link}/table`
      }

      return '';
    },
    mainColor() {
      return this.festival?.interface.main_color || '#ffffff'
    },
    gradientColor() {
      return this.festival?.interface.gradient_color || '#000000'
    },
    extraColor() {
      return this.festival?.interface.extra_color || '#000000'
    }
  },
  methods: {
    async fetch() {
      this.controller?.abort()
      clearTimeout(this.timer);

      this.controller = new AbortController()
      try {
        let response = await api.rating.get(this.$route.params.slug, this.$route.params.id, this.currentPage, this.controller);
        this.screen = response.data.screen;
        this.festival = response.data.festival;
        this.list = response.data.list;
        this.array = [];
        this.creatData1(response.data.list)

        this.timer = setTimeout(() => {
          if (this.currentPage < this.list.last_page) {
            this.currentPage += 1;
          } else {
            this.currentPage = 1
          }
        }, 10000)
      } catch(e) {
        console.log(e)
      }
    },
    creatData1(arr) {
      if (Object.prototype.toString.call(arr.data) === '[object Object]') {
        Object.keys(arr.data).forEach(el => {
          this.array.push(arr.data[el])
        })
      } else {
        arr.data.forEach(el => {
          this.array.push(el);
        })
      }

    },
    genderTitle(locale) {
      if (locale === 'ru') {
        return (this.screen.genders.length === 2) ? `${this.screen.genders[0].name_ru}/${this.screen.genders[1].name_ru}` : `${this.screen.genders[0].name_ru}`;
      } else {
        return (this.screen.genders.length === 2) ? `${this.screen.genders[0].name_en}/${this.screen.genders[1].name_en}` : `${this.screen.genders[0].name_en}`;
      }
    },
    getResult(item) {
      let exerciseID = this.screen.exercises[0].id;
      if (exerciseID && item.exercises[exerciseID]) {
        return item.exercises[exerciseID].value
      }
      return '-';
    },
  },
  watch: {
    currentPage() {
      this.fetch()
    }
  }
})
</script>

<style lang="scss">

.v-application ul, .v-application ol {
  padding-left: 0px !important;
}

.rating {
  width: 100%;
  height: 100%;

  max-height: 100vh;
  overflow-y: scroll;

  background-color: var(--bgColor);

  font-family: Roboto;

  &__bg {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 0;
    pointer-events: none;
    background-color: var(--circleColor);
    mask: url("./svg/auth-background.svg") no-repeat 0 / cover;
  }
  &__qr-container {
    &:before {
      content: "";

      width: 100%;
      height: 102%;

      position: absolute;
      top: -3px;
      left: -10px;
      transform: skewY(-8deg);
      z-index: -1;

      background-color: #dcdce0;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
  }

  &__qr {

    position: absolute;

    background: #fff;

    bottom: 15%;
    right: 3%;

    &--title {
      padding: 5px;
      color: #3C3C3C;
      font-weight: bold;
    }

    &--icon {
      padding: 2px 5px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;

    margin-top: 61px;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    z-index: 1;

    &--title {
      padding: 10px 25px;

      font-size: 25px;

      position: relative;

      background: var(--extra_color);
      color: #fff;

      &::after {
        content: 'Rating of participants';

        font-size: 16px;
        line-height: 19px;
        white-space: nowrap;

        position: absolute;
        bottom: -20px;
        right: 6%;

        color: #fff;
      }

      &::before {
        content: "";

        width: 100%;
        height: 102%;

        position: absolute;
        top: -3px;
        left: -10px;
        transform: skewY(-8deg);
        z-index: -1;

        background-color: #dcdce0;
      }
    }

    &--age-sex {
      display: flex;

      .age-sex-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 19px;
        font-weight: 500;

        &:nth-child(1) {
          margin-right: 20px;
        }
      }


    }
  }

  &__description {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 51px;
    margin-bottom: 57px;

    font-size: 20px;
    font-weight: 500;
    color: #FFFFFF;
    text-align: start;

    &--title {
      &.en {
        font-size: 16px;
        font-weight: normal;
      }
    }
  }

  &__table {
    display: flex;
    flex-direction: column;
    width: 100%;

    .table-header {
      display: grid;
      grid-template-columns: .2fr 3fr 1fr .4fr;

      &--with-result {
        grid-template-columns: .2fr 1fr 1fr .4fr .5fr;
      }

      .en {
        font-weight: normal;
        font-size: 12px;
      }

      div {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;

        color: #FFFFFF;

        &:nth-child(1) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        &:nth-child(2) {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-left: 23%;
          align-items: start;
        }

        &:nth-child(3) {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-left: 27%;
          align-items: start;
        }

        &:nth-child(4) {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-left: 25%;
          align-items: start;
        }

        &:nth-child(5) {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-left: 25%;
          align-items: start;
        }
      }
    }

    .table-items {
      display: grid;
      grid-auto-rows: 1fr;
      margin-top: 11px;
      max-height: 600px;
    }

    .table-item {
      display: grid;
      grid-template-columns: .2fr 3fr 1fr .4fr;

      &--with-result {
        grid-template-columns: .2fr 1fr 1fr .4fr .5fr;
      }

      margin-top: 10px;

      .table-item__place {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        background: #DBDBDB;
        clip-path: polygon(0% 0%, 100% 0%, 85% 100%, 0% 100%);

        color: #3C3C3C;
      }

      .table-item__name {
        margin-left: -10px;
        padding: 5px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        background: #FFFFFF;
        clip-path: polygon(1% 0%, 100% 0%, 100% 100%, 0% 100%);

        position: relative;


        .name-text {
          display: flex;
          margin-left: 25%;

          font-weight: bold;
          font-size: 18px;
          line-height: 21px;

          color: #3C3C3C;
          text-align: start;
        }

        .surname-text {
          display: flex;
          margin-left: 25%;

          font-size: 14px;
          line-height: 16px;

          color: #3C3C3C;
        }
      }

      .table-item__result {
        display: flex;
        align-items: center;
        justify-content: center;

        color: #3C3C3C;

        background: #DBDBDB;
        clip-path: polygon(14% 0%, 100% 0%, 87% 100%, 0% 100%);
      }

      .table-item__goals {
        display: flex;
        align-items: center;
        justify-content: center;

        color: #3C3C3C;

        background: #DBDBDB;
        clip-path: polygon(9% 0%, 100% 0%, 100% 100%, 0% 100%);
      }

      .table-item__date {
        display: flex;
        align-items: center;
        padding-left: 28%;
        margin-left: -13px;

        background: #FFFFFF;
        color: #3C3C3C;

        font-size: 16px;
        line-height: 19px;

        clip-path: polygon(0% 0%, 100% 0%, 97% 100%, 0% 100%);
      }

      &.gold {
        margin-top: 0px;

        .table-item__place {
          background: linear-gradient(180deg, #FED64D 0%, #CB9D04 100%);
        }

        .table-item__result {
          background: linear-gradient(180deg, #FED64D 0%, #CB9D04 100%);
        }

        .table-item__goals {
          background: linear-gradient(180deg, #FED64D 0%, #CB9D04 100%);
        }

        .table-item__name {
          &::before {
            content: "";
            width: 6%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            transform: skewY(0deg);
            z-index: -1;
            background-color: #FED64D;
            clip-path: polygon(0% 0%, 100% 0%, 64% 100%, 0% 100%);
          }
        }
      }

      &.silver {
        .table-item__place {
          background: linear-gradient(180deg, #B9B9B9 0%, #868686 100%);
        }

        .table-item__result {
          background: linear-gradient(180deg, #B9B9B9 0%, #868686 100%);
        }

        .table-item__goals {
          background: linear-gradient(180deg, #B9B9B9 0%, #868686 100%);
        }

        .table-item__name {
          &::before {
            content: "";
            width: 6%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            transform: skewY(0deg);
            z-index: -1;
            background-color: #B9B9B9;
            clip-path: polygon(0% 0%, 100% 0%, 64% 100%, 0% 100%);
          }
        }
      }

      &.bronze {
        .table-item__place {
          background: linear-gradient(180deg, #C4966B 0%, #B4753C 100%);
        }

        .table-item__result {
          background: linear-gradient(180deg, #C4966B 0%, #B4753C 100%);
        }

        .table-item__goals {
          background: linear-gradient(180deg, #C4966B 0%, #B4753C 100%);
        }

        .table-item__name {
          &::before {
            content: "";
            width: 6%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            transform: skewY(0deg);
            z-index: -1;
            background-color: #C4966B;
            clip-path: polygon(0% 0%, 100% 0%, 64% 100%, 0% 100%);
          }
        }
      }
    }
  }

  &__pagination {
    display: flex;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;

    div {
      width: 62px;
      height: 24px;
      cursor: pointer;

      background: #FFFFFF;
      opacity: .5;
      clip-path: polygon(14% 0%, 100% 0%, 87% 100%, 0% 100%);

      &.active {
        opacity: 1;
      }
    }
  }
}

</style>