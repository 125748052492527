<template>
  <div class="auth-date">
    <div class="auth-date__avatar">
      <div class="auth-date__avatar--title">
        {{locale.auth.photo_t}}
      </div>
      <div class="auth-date__avatar--content">
        <div class="content-avatar">
          <v-avatar
            v-if="!image"
            color="grey lighten-2"
            size="100"
          ></v-avatar>
          <v-avatar size="100" v-else>
            <img
              :src="imgResult ? imgResult :  image"
              alt="John"
            >
          </v-avatar>
        </div>
        <div class="content-action">
          <button @click="AvatarActive()">{{locale.buttons.chouse_photo}}</button>
           <input @change="(e) => handleImage(e)" type="file" accept="image/*" id="upload" style="display: none">
          <div>{{locale.auth.photo_d}}</div>
        </div>
      </div>
    </div>
    <div class="auth-date__year-user">
      <div class="auth-date__year-user--label">
        {{locale.auth.date}} 
        <div class="error-container__date">
          <span v-if="$v.date.$anyError" class="error-text ml-auto">{{locale.auth.error_require_date}}</span> 
          <span v-if="isValidDate" class="error-text ml-auto">{{locale.auth.error_date}}</span> 
        </div>
        
      </div>
      <div class="auth-date__year-user--input">
         <date-pick
          v-model="$v.date.$model"
          :displayFormat="'DD.MM.YYYY'"
          :isDateDisabled="isFutureDate"
          :months="locale.months"
          :weekdays="locale.weekdays"
          @input="(e) => setdate(e)"
        ></date-pick>
      </div>
    </div>
    <div class="auth-date__data-user">
      <div class="data-user__sex">
        <div class="data-user__sex--label">{{locale.auth.sex}}</div>
        <div class="data-user__sex--select">
          <v-select 
            dense
            outlined
            v-model.trim="sex"
            :items="locale.auth.sexSelect"
            item-text="text"
            :menu-props="{ buttom: false, offsetY: true }"
            hide-details
            item-value="id"
            @change="(e) => $emit('setValue', 'sex', e)"
          />
        </div>
      </div>

      <div class="data-user__team"  >
        <div class="data-user__team--label">{{locale.auth.team}}</div>
        <div class="data-user__team--select" v-if="countries">
          <v-select 
            :items="teams"
            dense
            outlined
            single-line
            :menu-props="{ buttom: true, offsetY: true, maxHeight: 150 }"
            hide-details
            item-text="name"
            item-value="id"
            v-model.trim="team"
            @change="(e) => $emit('setValue', 'team', e)"
          />
        </div>
      </div>
    </div>
    <div class="auth-date__checkbox">
     <v-checkbox  @change="(e) => $emit('setValue', 'agree', e)"  v-model.trim="$v.agree.$model" color="#BB9657" hide-details>
      <template v-slot:label>
        <div class="checkbox-title" @click="$emit('openAgree')">
          {{locale.auth.consent}}
        </div>
      </template>
    </v-checkbox>
     <div  v-if="$v.agree.$anyError" class="d-flex" style="font-weight: bold; color: red;">
      {{locale.auth.error_text}}
    </div>
    </div>
   
    <div class="auth-date__action">
     <button @click="$emit('goBack')" class="btn-back">{{locale.buttons.back}}</button>
     <button @click="$emit('registration')" class="btn-registration">{{locale.buttons.registration}}</button>
    </div>
     <Modal :activator="activatorCropper" @activatorChange="activatorChange">
      <template v-slot:content>
        <PictureCropper :image="image" @close="close" @setImage="setImage"/>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters} from 'vuex';
import { required, sameAs} from 'vuelidate/lib/validators';
import Modal from '@/components/common/modal/Modal';
import PictureCropper from '@/components/common/cropper/PictureCropper';
import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';
export default ({
  components: {
    Modal,
    PictureCropper,
    DatePick,
  },
  props: {
    imageUser: {
      required: true
    },
    dateUser: {
      required: true
    },
    sexUser: {
      required: true
    },
    teamUser: {
      required: true
    },
    agreeUser: {
      required: true
    }
  },
 data () {
  return({
    date: this.dateUser,
    sex: this.sexUser,
    team: this.teamUser,
    agree: this.agreeUser,
    image: this.imageUser,
    trigger: false,
    activatorCropper: false,
    imgResult: null,
    activePicker: null,
    activaDate: null,
    menu: false,
   
  })
 },
  validations: {
    date: {
      required,
    },
    agree: {
      required,
      sameAs: sameAs( () => true ) 
    },
  },
 computed: {
    ...mapGetters({
      locale: 'lang/locale',
      language: 'lang/language',
      countries: 'desk/countries',
      festivalInfo: 'desk/festivalInfo',
      teams: 'desk/teams'
    }),
    placeholderDate() {
      return this.language === 'ru' ? 'ДД.ММ.ГГГГ' : 'MM.DD.YYYY' 
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    isValidDate() {
      if( this.date === null) {
        return false
      }
      const today = new Date().getFullYear();
      const year = this.date.split('-')[0];
      const isValid = (Number(today) - Number(year)) > 5;
      return !isValid
    },
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  methods: {
    AvatarActive() {
      document.getElementById('upload').click();
      this.trigger = true;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}-${day}-${year}`;
    },
    isFutureDate(date) {
      const today = new Date(this.festivalInfo.settings.age_level_verification_date);
      const birthDate = new Date(date);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
      {
          age--;
      }
     return age < 6;
    },
    handleImage(e) {
      const input = e.target;

      if (input.files && input.files[0]) {
        const reader = new FileReader();
        reader.onload = e => {
          this.image = e.target.result;
          this.activatorCropper = true
        };

        reader.readAsDataURL(input.files[0]);
      }
    },
    close(){
      this.activatorCropper = false;
      this.image = null;
    },
    activatorChange(act) {
      if(act === false ) {
        this.image = null;
      }
      this.activatorCropper = act;
    },
    activatorChangeDate(act) {
      this.activePicker = act;
    },
    async setImage(data) {
      const {canvas} = data.getResult();
      this.roundEdges(canvas)
      this.activatorCropper = false;
    },
    async roundEdges(canvas) {
      this.imgResult = await canvas.toDataURL();
      this.$emit('setValue', 'image', this.imgResult)
    },
    setdate(e) {
      this.$emit('setValue','date', e)
    },
    activeDate() {
      this.activeDate = 'YEAR';
      this.activePicker = true;
    }
  }
})
</script>

<style lang="scss">
.auth-date {
 display: flex;
 flex-direction: column;
 margin-top: 10px;

  .error-text {
   color: red;
   font-size: 9px;
  }

  .error-container__date {
    display: flex;
    flex-direction: column;
  }

 .checkbox-title:hover {
  box-shadow: 0 3px 0px -1px var(--extra_color);
 }

 .auth-date__year-user--input {
  .vdpComponent {
    width: 100%;

    input {
      width: 100%;
      padding: 10px;
      font-size: 12px;
      border-radius: 5px;
      border: 1px solid #949494;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      color: #3c3c3c;
    }
  }
 }

 &__avatar {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   margin: 10px 0;

   &--title {
     font-weight: bold;
   }

   &--content {
     display: flex;
     margin-top: 10px;

    .content-action {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 10px;

      button {
        padding: 10px 25px;
        font-size:20px;
        background: var(--extra_color);
        color: #fff;
        margin-bottom: 5px;

        @media screen and (max-width: 768px) {
          padding: 10px 25px;
          font-size: 18px;
        }
      }
    }

   }
 }

 &__year-user {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  &--label {
   width: 100%;
   display: flex;
   align-items: baseline;
   justify-content: space-between;

   font-weight: bold;
  }

  &--input {
   margin-top: 10px;
   display: flex;
   justify-content: flex-start;
   width: 100%;
  }
 }

 &__data-user {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  flex-direction: column;

  .data-user__sex {
   display: flex;
   flex-direction: column;
   align-items: flex-start;

   &--label {
    font-weight: bold;
   }

   &--select {
    margin-top: 10px;
    width: 100%;
   }
  }

  .data-user__team {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   margin-top: 10px;


   &--label {
    font-weight: bold;
   }

   &--select {
    margin-top: 10px;
    width: 100%;
   }
  }


 }

 &__patronymic {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;

  &--label {
   font-weight: bold;
  }

  &--input {
   margin-top: 10px;
   display: flex;
   justify-content: flex-start;

   input {
    width: 460px;
    height: 40px;
    outline: 0;
    padding: 0 40px 0 10px;
    border-radius: 5px;
    border: 1px solid #949494;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #3c3c3c;
    letter-spacing: 0.5px;
    transition: border-color 0.15s ease-in-out;
   }
  }
 }

 &__action {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;

  .btn-registration {
   padding: 5px 18px; 

   background: var(--extra_color);

   color: #FFFFFF
  }

  .btn-back {
   color: grey;
  }
 }
}
</style>