<template>
 <div class="trial">
  <div class="trial__main">
   <div class="trial__main--search">
     <div class="search-container">
        <div class="search-title">{{locale.trial.title}}</div>
        <label>
          <input type="text" v-model="filterText" @change="onChangeItems" :placeholder="locale.trial.placeholder">
        </label>
     </div>
   </div>
   <div class="blocks-container" v-if="festivalInfo">
     <div class="trial__main--blocks">
      <template v-for="item in getItems">
        <div class="trial__main--blocks-item" :key="item.id" @click="goToTrialItemPage(item)" v-if="item">
        <div class="trial__main--blocks-icon" v-if="item.exercise.icon">
          <img :src="item.exercise.icon.url" alt="">
        </div>
          {{language === 'ru' ? item.exercise.title_ru : item.exercise.title_en}}
        </div>
      </template>
    </div>
   </div>
  </div>
 </div>
</template>


<script>
import {mapGetters, mapMutations} from 'vuex';

export default ({
 data () {
  return {
    e1: 1,
    filterText: ''
  }
 },
 computed: {
   ...mapGetters({
      festivalInfo: 'desk/festivalInfo',
      locale: 'lang/locale',
      language: 'lang/language',
      exerciseItem: 'trial/exerciseItem',
      isDesktop: 'app/isDesktop',
      isMobile: 'app/isMobile'
    }),
    getItems: function () {
      if(this.filterText === '') {
        return this.festivalInfo.exercises
      } 
      return this.festivalInfo.exercises.filter(el => this.language === 'ru'? el.exercise.title_ru.toUpperCase().includes(this.filterText.toUpperCase()) : el.exercise.title_en.toUpperCase().includes(this.filterText.toUpperCase()))
    }
  },
 methods: {
   ...mapMutations({
     setExerciseItem: 'trial/setExerciseItem'
   }),
   onChangeItems(value) {
     this.getItems(value.target.value)
   },
  goToTrialItemPage(item) {
    this.setExerciseItem(item)
    localStorage.setItem('exerciseItem', JSON.stringify(item));
    this.$router.push({name: 'trial.list.show', params: {slug: this.$route.params.slug, id: item.exercise.id}})
  }
 }
})
</script>


<style lang="scss">
.trial {
 height: 100%;
 padding-bottom: 150px;

 &__header {
  height: 100px;
  margin-bottom: -30px;
  width: 100%;
  position: relative;
  padding-bottom: 30px;
  z-index: 1;

  &::before {
   bottom: 30px;
   transform: skewY(0);
   background-color: #8F8F8F;
   content: "";
   position: absolute;
   bottom: 31px;
   left: 0;
   width: 100%;
   height: calc(100% - 34px);
   z-index: -1;
   transform-origin: left;
   transform: skewY(360deg);
  }

  &--block {
   box-shadow: none;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: calc(100% - 30px);
   z-index: -1;
   transform-origin: right;
   transform: skewY(1deg);
   overflow: hidden;

   .background {
    box-shadow: 0 15px 30px rgb(0 0 0 / 20%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: right;
    transform: skewY(360deg);
    background: linear-gradient(to right, var(--gradient_color) 0,var(--main_color) 100%);
    background-size: cover;
    background-position: center;
   
   }
  }

  &--title {
   padding: 10px 30px;

   position: absolute;
   bottom: -22px;
   left: 0px;

   font-size:35px;


   background: var(--main_color);
   color: #fff;

   &::before {
     content: "";

     width: 94%;
     height: 109%;
    
     position: absolute;
     top: -3px;
     left: 6px;
     transform: skewY(-8deg);
     z-index: -1;

     background-color: #dcdce0;
   }
  }
 }

 &__main {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 80px;
  padding: 0 3%;

  .blocks-container {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  &--blocks {
    margin-top: 50px;
    display: grid;
    width: 100%;
    max-width: 1500px;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }

   &-item {
    display: flex;
    align-items: center;
    padding: 20px;

    border: 1px solid var(--extra_color);
    border-radius: 4px;

    cursor: pointer;

    font-size: 14px;

    &:hover {
     background: #EFEFEF;
    }
   }

   &-icon {
    margin-right: 20px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items:center;
    justify-content: center;


    img {
      width: 30px;
      height: 30px;
    }
   }
  }

  &--search {
   width: 100%;
   display: flex;
   justify-content: center;
   

   .search-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    max-width: 1500px;

    @media screen and (max-width: 768px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      label {
        margin-top: 20px;

        input {
          width: 100%;
        }
      }

    }
   }

   .search-title {
     display: flex;
     align-items: flex-end;
     font-family: Roboto;
     font-style: normal;
     font-weight: normal;
     font-size: 26px;
     line-height: 30px;
   }

   label {
    position: relative;

    &::before {
     content: '';
     width: 20px;
     height: 20px;
     position: absolute;
     background: url(../svg/loup.svg) no-repeat center;
     top: 50%;
     right: 15px;
     -webkit-transform: translateY(-50%);
     -ms-transform: translateY(-50%);
     transform: translateY(-50%);
    }

    input {
     width: 460px;
     height: 40px;
     outline: 0;
     padding: 0 40px 0 10px;
     border-radius: 5px;
     border: 1px solid #949494;
     font-family: 'Roboto',sans-serif;
     font-size: 14px;
     color: #3c3c3c;
     letter-spacing: .5px;
     transition: border-color .15s ease-in-out;
    }
   }
  }
 }
}
</style>