<template>

  <div>
    <div class="table__header">
      <div class="table__header--block">
        <div class="background"></div>
      </div>
      <div class="title-block">
        <div class="title-container">
          <div class="table__header--title">{{ locale.table.logo }}</div>
        </div>
      </div>
    </div>
    <div class="table__tabs-view">
      <div class="table__tabs-view--items">
        <div class="table__tabs-view--items-title">
          {{ locale.table.view }}:
        </div>
        <div class="table__tabs-view--items-item mr-1" :class="{ active: $route.name === 'table.index' }"
             @click="goToCompact()">
          {{ locale.table.compact }}
        </div>
        /
        <div class="table__tabs-view--items-item ml-1" :class="{ active: $route.name === 'table.extended.index' }"
             @click="goToExtended()">
          {{ locale.table.extended }}
        </div>
      </div>
    </div>
    <div v-if="showDescription">
      <div class="table__description">
        <div class="table__description-container">
          <div class="table__description-container--text">
            {{ locale.table.description }}
          </div>
        </div>
      </div>
      <div class="table__divider">
        <v-divider color="#BB9657" class="mt-15" style="maxWidth: 1500px"></v-divider>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "TablesNavigation",
  computed: {
    ...mapGetters({
      locale: 'lang/locale',
      isDesktop: 'app/isDesktop',
      isMobile: 'app/isMobile',
    }),
    showDescription() {
      return this.$route.name === 'table.index';
    }
  },
  methods: {
    goToCompact() {
      this.$router.push({name: 'table.index', params: {slug: this.$route.params.slug}});
    },
    goToExtended() {
      this.$router.push({name: 'table.extended.index', params: {slug: this.$route.params.slug}});
    }
  }
}
</script>

<style scoped>

</style>