let domain = process.env.VUE_APP_API_URL;
import axios from "axios";

export const api = {
  festival: {

    get: async (slug) => axios.get(`${domain}festivals/${slug}`),
  },
  exercises: {
    get: async (slug, exercise_id, gender_id, age_category_id) =>
      axios.get(`${domain}festivals/${slug}/exercises/${exercise_id}`, {
        params: {
          gender_id: gender_id,
          age_category_id: age_category_id,
        },
      }),
  },
  registration: {
    store: async (slug, data = {}) =>
      axios.post(`${domain}festivals/${slug}/registration`, data),
  },
  tournamentTable: {
    get: async (slug, data = {}) =>
      axios.get(`${domain}festivals/${slug}/tournament-table`, {
        params: data,
      }),
    export: async (slug, data = {}) =>
      axios.get(`${domain}festivals/${slug}/tournament-table/export`, {
        params: data,
      }),
  },
    rating: {
        get: async (slug, id, page, controller) =>
            axios.get(`${domain}festivals/${slug}/rating/${id}`, {
                params: {
                    page: page,
                },
                signal: controller.signal
            }),
    },
    ais: {
        search: async (uin) =>
            axios.get(`${domain}/ais/users`, {
                search: uin,
            }),
    }
};
