<template>
  <div class="auth-success">
    <div class="auth-success__icon">
      <svg width="129" height="144" viewBox="0 0 129 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M85.7933 142.636L127.633 118.503L115.226 97.0346L73.3865 121.167L85.7933 142.636Z" stroke="#BB9657" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M114.753 115.427C116.284 114.544 116.808 112.588 115.924 111.058C115.04 109.528 113.082 109.004 111.551 109.887C110.02 110.77 109.496 112.726 110.38 114.256C111.264 115.786 113.222 116.31 114.753 115.427Z" stroke="#BB9657" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M109.706 100.332C109.706 100.332 109.594 70.155 98.927 56.6879C88.2596 43.2209 77.4643 26.1341 72.2846 30.1615C67.0549 34.1023 86.932 56.9142 86.932 56.9142L64.331 29.7873C64.331 29.7873 56.651 21.2912 52.9169 24.0228C47.947 27.8137 64.1243 47.22 77.995 64.0329C53.5204 34.0626 50.5538 30.9265 46.2002 25.5897C41.6234 20.2662 36.4183 18.6525 34.503 20.3347C32.5878 22.0169 31.4459 24.6379 39.8834 36.0443C48.4575 47.4872 66.5414 68.7945 66.5414 68.7945C58.7918 61.3773 39.5336 63.0241 35.5126 73.6546C31.4916 84.285 33.349 97.2949 37.1709 97.5136C41.0427 97.819 41.8982 86.7065 45.6251 82.3632C49.3519 78.0199 61.9105 78.9689 64.6952 85.788C67.48 92.6071 65.8232 99.3342 53.9111 98.7047C41.9991 98.0751 38.6944 99.751 38.3089 102.282C37.9233 104.813 42.8295 108.907 54.4085 109.96C65.9875 111.013 75.9234 110.82 80.2574 117.322L109.706 100.332Z" stroke="#BB9657" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <div class="auth-success__title">
      {{locale.auth.thanks}}
    </div>
    <div class="auth-success__description">
      {{locale.auth.thanks_des}}
    </div>
     <div class="auth-success__action">
     <button @click="$emit('close', false)">{{locale.buttons.ok}}</button>
    </div>
  </div>
</template>


<script>
import { mapGetters} from 'vuex';

export default ({
  computed: {
    ...mapGetters({
      locale: 'lang/locale',
      language: 'lang/language',
      isDesktop: 'app/isDesktop',
      isMobile: 'app/isMobile'
    }),
  },
  methods: { }
})
</script>

<style lang="scss">
.auth-success {
  width: 500px;
  height: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
  padding: 50px;
  position: relative;

  @media screen and (max-width: 768px) {
    padding: 0 20px;
    min-width: 300px;
    min-height: 300px;
    width: 100%;
    height: 100%;
  }

  &__title {
    margin-top: auto;
    width: 100%;
    font-size: 42px;
    font-weight: bold;
  }

  &__description {
    width: 100%;

    font-size: 28px;
    font-weight: 300;
  }

  &__action {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;

    button {
    padding: 5px 18px; 

    background: var(--extra_color);

    color: #FFFFFF
    }
  }
  
}
</style>