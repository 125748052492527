<template>
 <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
 <path d="M24.9995 0.000488281H-0.000488281V24.9162H24.9995V0.000488281Z" fill="#BB9657"/>
 <path d="M9.06151 4.17341L3.12451 3.11499L4.18651 9.03196L5.56151 7.6616L7.99951 10.0914L10.1245 7.97354L7.68651 5.54377L9.06151 4.17341Z" fill="white"/>
 <path d="M16.9995 10.0914L19.4375 7.6616L20.8125 9.03196L21.8745 3.11499L15.9365 4.17341L17.3115 5.54377L14.8745 7.97354L16.9995 10.0914Z" fill="white"/>
 <path d="M7.99951 14.8253L5.56151 17.2541L4.18651 15.8837L3.12451 21.8017L9.06151 20.7433L7.68651 19.3729L10.1245 16.9431L7.99951 14.8253Z" fill="white"/>
 <path d="M19.4375 17.2541L16.9995 14.8253L14.8745 16.9431L17.3125 19.3729L15.9375 20.7433L21.8745 21.8017L20.8125 15.8847L19.4375 17.2541Z" fill="white"/>
 </svg>
</template>
<script>
export default ({

})
</script>
