<template>
 <div class="map">
  <div class="map__header">
   <div class="map__header--block">
    <div class="background"></div>
   </div>
   <div class="title-block">
    <div class="title-container">
     <div class="map__header--title">{{locale.map.logo}}</div>
    </div>
   </div>
  </div>
  <div class="map__main" v-if="festivalInfo">
   <div class="map__main--container" v-if="festivalInfo.interface.map_image">
     <img :src="festivalInfo.interface.map_image.url" alt="">
   </div>
  </div>
 </div>
</template>

<script>
import { mapGetters} from 'vuex';
export default ({
 computed: {
   ...mapGetters({
      festivalInfo: 'desk/festivalInfo',
      locale: 'lang/locale',
    }),
 }
 
})
</script>


<style lang="scss">
.map {
 padding-bottom: 100px;

 &__header {
  height: 100px;
  margin-bottom: -30px;
  width: 100%;
  position: relative;
  padding-bottom: 30px;
  z-index: 1;

  .title-block {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .title-container {
    width: 100%;
    height: 100%;
    max-width: 1500px;
    position: relative;
  }

  &::before {
   bottom: 30px;
   transform: skewY(0);
   background-color: #8F8F8F;
   content: "";
   position: absolute;
   bottom: 31px;
   left: 0;
   width: 100%;
   height: calc(100% - 34px);
   z-index: -1;
   transform-origin: left;
   transform: skewY(360deg);
  }

  &--block {
   box-shadow: none;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: calc(100% - 30px);
   z-index: -1;
   transform-origin: right;
   transform: skewY(1deg);
   overflow: hidden;

   .background {
    box-shadow: 0 15px 30px rgb(0 0 0 / 20%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: right;
    transform: skewY(360deg);
    background: linear-gradient(to right, var(--gradient_color) 0,var(--main_color) 100%);
    background-size: cover;
    background-position: center;
   
   }
  }

  &--title {
   padding: 10px 30px;

   position: absolute;
   bottom: -29px;
   left: 0px;

   font-size:35px;


   background: var(--main_color);
   color: #fff;

   &::before {
     content: "";

     width: 94%;
     height: 109%;
    
     position: absolute;
     top: -3px;
     left: 6px;
     transform: skewY(-8deg);
     z-index: -1;

     background-color: #dcdce0;
   }

   @media screen and (max-width: 320px) {
     font-size: 24px;
   }
  }
 }

 &__main {
  display: flex;
  justify-content: center;
  width: 100%;
  max-height: 50%;
  margin-top: 6%;
  padding: 60px 0px;

  @media screen and (max-width: 768px) {
    margin-top: 100px;
  }

  &--container {
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   max-width: 1500px;
   background-size: cover;

   img { 
    width: 100%;
    height: 100%;
    max-width: 1500px;
   }
  }
  
  
  background: #EFEFEF;
  
 }
}
</style>