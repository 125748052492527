<template>
  <v-dialog
    v-model="activatorChange"
    v-click-outside="closeM"
    activator="activatorChange"
    :origin="origin"
    :persistent="false"
    :fullscreen="fullScreen"
    :hide-overlay="fullScreen"
    :width="width"
    :content-class="!fullScreen ? `modal-window ${modalClass}` : ''"
    :max-width="!fullScreen ? modalMaxWidth : ''"
    :transition="(fullScreen || fromBottom) && 'dialog-bottom-transition'"
  >
    <v-card
      v-if="!fullScreen && withoutToolBar"
      class="modal-content"
      :color="color ? color : ''"
    >
      <slot name="content" />
    </v-card>
    <v-card
      v-else-if="fullScreen && withoutToolBar"
      :style="{ overflow: 'scroll' }"
      :color="color ? color : ''"
    >
      <slot name="content" />
    </v-card>
    <template v-else>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ toolBarTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <div
          style="cursor: pointer"
          @click.stop="
            activatorChange = false
            $emit('close')
          "
        >
          <v-icon>mdi-close</v-icon>
        </div>
      </v-toolbar>
      <v-card>
        <slot name="full-screen-content" />
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
export default {
  name: 'Modal',
  props: {
    activator: {
      required: true,
      type: Boolean,
    },
    fullScreen: {
      default: false,
      type: Boolean,
    },
    fromBottom: {
      default: false,
      type: Boolean,
    },
    withoutToolBar: {
      default: true,
      type: Boolean,
    },
    toolBarTitle: {
      default: 'Ответ пользователю',
      type: String,
    },
    modalClass: String,
    videoModal: Boolean,
    origin: String,
    maxWidth: String,
    width: String,
    color: String,
    minWidth: String,
  },

  computed: {
    activatorChange: {
      get() {
        return this.activator
      },
      set(activator) {
        this.$emit('activatorChange', activator)
      },
    },
    modalMaxWidth: {
      get() {
        if (this.maxWidth) {
          return this.maxWidth
        } else if (this.videoModal) {
          return 600
        } else return 600
      },
    },
  },

  methods: {
    closeM(e) {
      if (e.target.classList[0] === 'v-overlay__scrim') {
        this.activatorChange = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.modal-window {
  border-radius: 12px !important;
}

.modal-content {
  border-radius: 12px !important;
  max-height: 900px;
  overflow: auto;
}
</style>
