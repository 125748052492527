<template>
  <div class="auth-data">
    <div class="auth-data__number-user">
      <div class="auth-data__number-user--label">
       {{locale.auth.user_id}} <span v-if="$v.id.$anyError" class="error-text ml-auto">{{locale.auth.error_text}}</span>
      </div>
      <div class="auth-data__number-user--input">
        <v-icon large color="var(--extra_color)">mdi-card-bulleted-outline</v-icon>
        <input type="text" @change="$emit('setValue', 'id', $v.id.$model)" v-model.trim="$v.id.$model" class="id-input" :class="{mobile: isMobile, 'error-input': $v.id.$error}"  />
      </div>
    </div>
    <div class="auth-data__data-user">
      <div class="data-user__name">
        <div class="data-user__name--label">{{locale.auth.name}} <span v-if="$v.name.$anyError" class="error-text ml-auto">{{locale.auth.error_text}}</span> </div>
        <div class="data-user__name--input">
          <input type="text" v-on:keypress="isLetter($event)"  v-model.trim="$v.name.$model" @change="$emit('setValue', 'name', $v.name.$model)" class="input-name" :class="{mobile: isMobile, 'error-input': $v.name.$error}">
        </div>
      </div>
      <div class="data-user__surname">
        <div class="data-user__surname--label">{{locale.auth.surname}} <span v-if="$v.surname.$anyError" class="error-text ml-auto">{{locale.auth.error_text}}</span> </div>
        <div class="data-user__surname--input">
          <input type="text" v-on:keypress="isLetter($event)" v-model.trim="$v.surname.$model" @change="$emit('setValue', 'surname', $v.surname.$model)"
          :class="{'error-input': $v.surname.$error}" class="input-surname">
        </div>
      </div>
    </div>
    <div class="auth-data__patronymic">
      <div class="auth-data__patronymic--label">
        {{locale.auth.patronymic}}
      </div>
      <div class="auth-data__patronymic--input">
        <input type="text" v-on:keypress="isLetter($event)" v-model="patronymic" @change="$emit('setValue', 'patronymic',patronymic)" class="patronymic-input">
      </div>
    </div>
    <div class="auth-data__action">
     <button class="btn-next" @click="$emit('turnPage')">{{locale.buttons.next}}</button>
    </div>
  </div>
</template>


<script>
import { mapGetters} from 'vuex';
import { required } from 'vuelidate/lib/validators'
const numeric = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  }
  return /[0-9.-]/.test(value)
}
export default ({
  props: {
    idUser: {
      required: true,
    },
    nameUser: {
      required: true,
    },
    surnameUser: {
      required: true,
    },
    patronymicUser: {
      required: true,
    }
  },
  data() {
    return ({
      id: '',
      name: this.nameUser,
      surname: this.surnameUser,
      patronymic: this.patronymicUser,
    })
  },
  validations: {
    id: {
      required,
      numeric
    },
    name: {
      required,
      
    },
    surname: {
      required
    },
  },
  computed: {
    ...mapGetters({
      locale: 'lang/locale',
      language: 'lang/language',
      isDesktop: 'app/isDesktop',
      isMobile: 'app/isMobile'
    }),
  },
  methods: {
    isLetter(e) {
      if(this.language === 'en') {
         let char = String.fromCharCode(e.keyCode); // Get the character
          if(/^[A-Za-z]+$/.test(char)) return true; // Match with regex 
          else e.preventDefault(); // If not match, don't add to input text
      }

      if(this.language === 'ru') {
        let char = String.fromCharCode(e.keyCode); // Get the character
        if(/^[А-Яа-яЁё]+$/.test(char)) return true; // Match with regex 
        else e.preventDefault(); // If not match, don't add to input text
      }
    },
    clearData() {
      this.id = null;
      this.name = null;
      this.surname = null;
      this.patronymic = null;
    }
  }
})
</script>

<style lang="scss">
.auth-data {
 display: flex;
 flex-direction: column;
 margin-top: 40px;

 .error-text {
   color: red;
   font-size: 9px;
 }

 @media  screen and (max-width: 768px) {
   margin-top: 10px;
 }

 &__number-user {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &--label {
   width: 100%;
   display: flex;
   align-items: center;

   font-weight: bold;
  }

  &--input {
   margin-top: 10px;
   display: flex;
   justify-content: flex-start;
   width: 100%;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

   input {
    width: 460px;
    height: 40px;
    outline: 0;
    padding: 0 40px 0 10px;
    margin-left: 5px;
    border-radius: 5px;
    border: 1px solid #949494;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #3c3c3c;
    letter-spacing: 0.5px;
    transition: border-color 0.15s ease-in-out;

    @media screen and (max-width: 768px)  {
      width: 100%;
    }

    &.error-input {
      border: 1px solid red;
    }
   }
  }
 }

 &__data-user {
  display: flex;
  justify-content: flex-start;
  margin-top: 40px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-top: 10px;
  }

  .data-user__name {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   

   &--label {
    width: 100%;
    display: flex;
    align-items: center;

    font-weight: bold;
   }

   &--input {
    margin-top: 10px;
    width: 100%;

    input {
     width: 100%;
     height: 40px;
     outline: 0;
     padding: 0 40px 0 10px;
     
     border-radius: 5px;
     border: 1px solid #949494;
     font-family: "Roboto", sans-serif;
     font-size: 14px;
     color: #3c3c3c;
     letter-spacing: 0.5px;
     transition: border-color 0.15s ease-in-out;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 10px;
    }

    .error-input {
      border: 1px solid red;
    }
   }

   @media screen and (max-width: 768px) {
     flex: 0;
   }
  }

  .data-user__surname {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    margin-left: 20px;

    @media screen and (max-width: 768px) {
      margin-left: 0px;
      width: 100%;
    }


   &--label {
    width: 100%;
    display: flex;
    align-items: center;

    font-weight: bold;
   }

   &--input {
    margin-top: 10px;
    width: 100%;

    input {
     width: 100%;
     height: 40px;
     outline: 0;
     padding: 0 40px 0 10px;
     
     border-radius: 5px;
     border: 1px solid #949494;
     font-family: "Roboto", sans-serif;
     font-size: 14px;
     color: #3c3c3c;
     letter-spacing: 0.5px;
     transition: border-color 0.15s ease-in-out;

      &.error-input {
        border: 1px solid red;
      }
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      margin-left: 0;
    }
   }
  }
 }

 &__patronymic {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;

  @media screen and (max-width: 768px) {
    margin-top: 1px;
  }

  &--label {
   width: 100%;
   display: flex;
   align-items: center;

   font-weight: bold;
  }

  &--input {
   margin-top: 10px;
   display: flex;
   justify-content: flex-start;
   width:  100%;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

   input {
    width:  100%;
    height: 40px;
    outline: 0;
    padding: 0 40px 0 10px;
    border-radius: 5px;
    border: 1px solid #949494;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #3c3c3c;
    letter-spacing: 0.5px;
    transition: border-color 0.15s ease-in-out;
    
    @media screen and (max-width: 768px) {
      width: 100%;
    }

    &.error-input {
      border: 1px solid red;
    }
    
   }
  }
 }

 &__action {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 768px) {
    margin-top: 10px;
  }

  button {
   padding: 5px 18px; 

   background: var(--extra_color);

   color: #FFFFFF
  }
 }
}
</style>