<template>
    <v-app>
        <NavUp ref="nav"/>
        <v-main :style="styles">
          <v-card height="100%">
            <router-view></router-view>
          </v-card>
        </v-main>
        <Footer/>
    </v-app>
</template>
<script>
import NavUp from '@/components/common/NavUp.vue';
import Footer from '@/components/common/Footer.vue';
export default {
  name: 'App',
  components: {
    NavUp,
    Footer
  },
  computed: {
        styles() {
              return {background: '#E5E5E5', padding: '0 0 0 0', };
        },
    },
};
</script>
