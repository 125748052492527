export default {
  namespaced: true,
  state: {
    exerciseItem: null,
  },
  getters: {
    exerciseItem: (state) => state.exerciseItem,
  },
  mutations: {
    setExerciseItem(state, payload) {
      state.exerciseItem = payload;
    },
  },
};
