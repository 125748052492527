import Vue from "vue";
import VueRouter from "vue-router";
import Desk from "@/views/Desk/Desk.vue";
import Table from "@/views/Table/Table.vue";
import Trial from "@/views/Trial/Trial.vue";
import Map from "@/views/Map/Map.vue";
import Contacts from "@/views/Contacts/Contacts.vue";
import Auth from "@/views/Auth/Auth.vue";
import TrialItemInfo from "@/views/Trial/TrialItemInfo/TrialItemInfo.vue";
import TrialList from "@/views/Trial/TrialList/TrialList.vue";
import Rating from "@/views/Rating/Rating.vue";
import TableCompact from "../views/Table/TableCompact";
import TableExtended from "../views/Table/TableExtended";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:slug",
    name: "desk.index",
    component: Desk,
    meta: {
      title: "Главная",
      title_en: "Main",
    },
  },
  {
    path: "/:slug/registration",
    name: "registration.index",
    meta: {
      layout: "no-sidebar",
      title: "Регистрация",
      title_en: "Registration",
    },
    component: Auth,
  },
  {
    path: "/:slug/rating/:id",
    name: "rating.index",
    meta: {
      layout: "no-sidebar",
      title: "Рейтинг",
      title_en: "Raiting",
    },
    component: Rating,
  },
  {
    path: "/:slug/table",
    component: Table,
    meta: {
      title: "Турнирная таблица",
      title_en: "Standing",
    },
    children: [
      {
        path: "",
        name: "table.index",
        component: TableCompact,
        meta: {
          title: "Турнирная таблица",
          title_en: "Standing",
        },
      },
      {
        path: "extended",
        name: "table.extended.index",
        component: TableExtended,
        meta: {
          title: "Расширенная турнирная таблица",
          title_en: "Standing",
        },
      },
    ],
  },
  {
    path: "/:slug/trial",
    component: Trial,
    meta: {
      title: "Испытания",
      title_en: "Trial",
    },
    children: [
      {
        path: "",
        name: "trial.list.index",
        component: TrialList,
        meta: {
          title: "Испытания",
          title_en: "Trial",
        },
      },
      {
        path: ":id",
        name: "trial.list.show",
        component: TrialItemInfo,
        meta: {
          title: "Испытания",
          title_en: "Trial",
        },
      },
    ],
  },
  {
    path: "/:slug/map",
    name: "map.index",
    component: Map,
    meta: {
      title: " Карта",
      title_en: "Map",
    },
  },
  {
    path: "/:slug/contacts",
    name: "contacts.index",
    component: Contacts,
    meta: {
      title: "Контакты",
      title_en: "Contacts",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
