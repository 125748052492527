import { render, staticRenderFns } from "./TelegramSvg.vue?vue&type=template&id=da1f674e&scoped=true&"
var script = {}
import style0 from "./TelegramSvg.vue?vue&type=style&index=0&id=da1f674e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da1f674e",
  null
  
)

export default component.exports