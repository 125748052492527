<template>
  <div class="auth">
    <div class="auth__container">
      <div class="title-block">
        <div class="title-container">
          <div class="auth__title">{{locale.auth.registration}}</div>
        </div>
      </div>
      <div class="auth__lang" v-if="festivalInfo && festivalInfo.languages.length > 1">
        <span class="lang-item" @click="changeLang('ru')" :class="{active: language === 'ru'}">Русский</span>
        <span class="lang-item" @click="changeLang('en')" :class="{active: language === 'en'}">English</span>
      </div>
      <AuthUserData ref="authdata" :idUser="id" :nameUser="name" 
        :surnameUser="surname" :patronymicUser="patronymic" @setValue="setValue" @turnPage="turnPage" v-if="page === 1"
      />

      <AuthUserDate ref="authdate" :imageUser="image" :dateUser="date" :sexUser="sex" :teamUser="team"
                    :agreeUser="agree" @goBack="page =  1" @setValue="setValue" @openAgree="openAgree"
                    @registration="registration" v-if="page === 2"
      />
    </div>
      <Modal 
      :max-width="'max-content'"
      :activator="activatorSuccess"
      @activatorChange="changeModalSuccess"
    >
       <template #content>
         <ModalSuccess @close="changeModalSuccess"/>
      </template>
    </Modal>
    <Modal 
      :max-width="'max-content'"
      :activator="activatorError"
      @activatorChange="changeModalError"
    >
       <template #content>
        <ModalError :text="textError" @close="changeModalError"/>
      </template>
    </Modal>
     <Modal 
      :max-width="'max-content'"
      :activator="activatoAgree"
      @activatorChange="changeModalAgree"
      width="100%"
      v-if="festivalInfo"
    >
       <template #content>
        <ModalAgreeRus :userName="name" :userSurname="surname" :patronymicUser="patronymic" :namePlace="festivalInfo && festivalInfo.location.title_ru" :name="festivalInfo.title_ru" v-if="language === 'ru'"/>
        <ModalAgreeEn :namePlace="festivalInfo && festivalInfo.location.title_en" :name="festivalInfo.title_en" v-else />
      </template>
    </Modal>
  </div>
</template>


<script>
// import router from "@/router";
import AuthUserData from '@/views/Auth/AuthUserData/AuthUserData.vue';
import AuthUserDate from '@/views/Auth/AuthUserDate/AuthUserDate.vue';
import Modal from '@/components/common/modal/Modal.vue';
import { mapGetters, mapActions} from 'vuex';
import {api} from "@/api/api";
import ModalSuccess from './Modal/ModalSuccess.vue';
import ModalError from './Modal/ModalError.vue';
import ModalAgreeRus from './Modal/ModalAgreeRus.vue';
import ModalAgreeEn from './Modal/ModalAgreeEn.vue';

export default ({
  components: {
    AuthUserData,
    AuthUserDate,
    Modal,
    ModalSuccess,
    ModalError,
    ModalAgreeRus,
    ModalAgreeEn,
  },
  data(){
    return({
      page: 1,
      activatorModal: false,
      id: null,
      name: null,
      surname: null,
      patronymic: null,
      image: null,
      date: null,
      sex: null,
      team: null,
      agree: false,
      activatorSuccess: false,
      activatorError: false,
      success: false,
      error: false,
      activatoAgree: false,
      textError: '',
      typeError: null
    })
  },
  computed: {
    ...mapGetters({
      locale: 'lang/locale',
      language: 'lang/language',
      isDesktop: 'app/isDesktop',
      isMobile: 'app/isMobile',
      festivalInfo: 'desk/festivalInfo',
    }),
  },
  //TODO: пока не знаю как получить slug 

  // beforeRouteEnter(_, __, next) {
  //   console.log(router.history.current);
  //    const data = async function checkRegistration() {
  //     const {data} = await api.festival.get(router.history.current.params.slug)
  //     console.log(data);
  //     const today = new Date().getTime();
  //     const start = new Date(data.festivalInfo.opening_date).getTime();
  //     const end = new Date(data.festivalInfo.ending_date).getTime();
      
  //     if(start > today || end < today) {
  //       return false
  //     }

  //      if(data.festivalInfo.settings.credit_type === 'team_and_personal' && data.festivalInfo.teams.list.length === 0) {
  //         return false;
  //       }

  //     return true;

  //   }
  //    if(!data()){
  //     next({name: "desk.index"})
  //     }else {
  //       next()
  //     }
  // },
  methods: {
     ...mapActions({
      changeLanguage: 'lang/changeLanguage'
    }),
    turnPage() {
      this.$refs.authdata.$v.$touch();
      if(this.$refs.authdata.$v.$error) {
        return
      }

      this.page = 2;
    },
   
    async registration() {
      this.$refs.authdate.$v.$touch();
      
      if(this.$refs.authdate.$v.$error) {
        return
      }

      this.sendData().then(() => {
        this.activatorSuccess = true;
        this.success = true;
      }, (rej) => {
        if(rej.response.data.error.birthday) {
          this.openErrorModal('date')
          this.error = true;
          return;
        }

        if(rej.response.data.error.uin) {
          this.openErrorModal('id')
          this.error = true;
          return;
        }
      })
    },
    changeModal(value) {
      this.activatorModal = !!value
      this.clearData()
    },
    async sendData() {
      return await api.registration.store(this.$route.params.slug, {
          uin: this.id,
          name: this.name,
          surname: this.surname,
          patronymic: this.patronymic,
          photo_base_64: this.image,
          birthday: this.date,
          gender: {
            id: this.sex
          },
          team: {
            id: this.team
          },
        })
    },
    clearData() {
      this.id = null;
      this.name = null;
      this.surname = null;
      this.patronymic = null;
      this.image = null;
      this.date = null;
      this.sex = null;
      this.team = null;
      this.agree = false;
    },
    changeLang(text) {
      this.changeLanguage(text)
      this.clearData()
      this.page = 1

      if(this.$refs.authdata) {
        this.$refs.authdata.clearData()
      }
    },
     changeModalSuccess(value) {
      if(this.success) {
        this.activatorSuccess = !!value
        this.clearData();
        this.page = 1;
        this.success = false;
      }
    },
     changeModalError(value) {
       if(this.error) {
         this.page = 1;
         this.activatorError = !!value
       }
    },
    setValue(key, value) {
      this[key] = value
    },
    changeModalAgree(value) {
      this.activatoAgree = !!value
    },
    openAgree() {
      this.activatoAgree = true;
    },
    openErrorModal(errorType = 'id') {
      
      if(errorType ===  'date') {
        const date = new Date(Date.now()).toLocaleString().split(',')[0]
        this.textError = this.locale.auth.error_date_modal + ' ' + date;
      }

      if(errorType === 'id') {
        this.textError = this.locale.auth.error_des
      }
      this.activatorError = true;
    }
  }
})
</script>

<style lang="scss">
.modal-tasks {
    display: flex;
    flex-direction: column;
    padding: 20px;

    .modal-title {
      font-size: 18px;
      color: #000;
    }

    .modal-btn {
      margin-top: 20px;
      button {
        padding: 5px 18px; 
        color: #fff;

        background: var(--extra_color);

      }
    }
}
.auth {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(./svg/auth-background.svg) no-repeat;
  background-size: cover;

  @media screen and (max-width: 768px) {
    padding: 30px 0px;
  }

  &__title {
   padding: 10px 25px;
   min-width: 170px;

   position: absolute;
   top: -5%;
   right: 34%;

   font-size:20px;


   background: var(--extra_color);
   color: #fff;

   @media screen and (max-width: 768px) {
     right: 21%
   }

   &::before {
     content: "";

     width: 94%;
     height: 109%;
    
     position: absolute;
     top: -3px;
     left: 6px;
     transform: skewY(-8deg);
     z-index: -1;

     background-color: #dcdce0;
   }
  }

  &__lang {
    margin-top: 10px;

    .lang-item {
      padding: 1px 20px;

      border: 1px solid var(--main_color);

      cursor: pointer;

      &.active {
        background: var(--main_color);
        color: #ffff;
      }

      &:nth-child(1) {
        border-radius: 5px 0px 0px 5px;
      }

      &:nth-child(2) {
        border-radius: 0px 5px 5px 0px;
      }
    }
  }

  &__container {
    padding: 50px 60px;
    display: flex;
    flex-direction: column;
    max-width: 520px;
    height: 650px;
    
    position: relative;
    z-index: 1;

    background: #fff;

    @media screen and (max-width: 768px) {
      padding: 20px 10px;
      height: 100%;
    }

    .title-block {
      display: flex;
      width: 100%;
      height: 100%;
      pointer-events: none;

      position: absolute;
      top: 0;
      left: 0;
      
    }

    .title-container {
      width: 100%;
      height: 100%;
      max-width: 1500px;
    }
  }
}
</style>