<template>
  <div class="table">

    <TablesNavigation></TablesNavigation>

    <div class="table-extended" v-if="festivalInfo" :class="{'full': full}">
      <div class="table-compact__blocks" style="width: 100%; max-width: 1500px">
        <div class="table-compact__block-table">
          <div class="block-table__header flex-column" style="width: 100%">

            <div class="header-tabs-raiting-extend ml-0">
              <div class="header-tabs-raiting--items align-baseline">
                <div class="header-tabs-raiting--items-title">
                  Тип зачета:
                </div>
                <div class="table-compact__tabs-view--items-item mr-1 d-flex align-center"
                     :class="{ active: isPersonalCreditType}"
                     @click="switchCreditType('personal')">
                  {{ locale.table.individual }}
                </div>

                <div class="table-compact__tabs-view--items-item ml-1 d-flex align-center"
                     v-if="festivalInfo.settings.credit_type === 'team_and_personal'"
                     :class="{ active: isTeamAndPersonalCreditType}"
                     @click="switchCreditType('team_and_personal')">
                  /{{ locale.table.team }}
                </div>
              </div>
            </div>
            <div class="block-actions">
              <div class="header-search">
                <label>
                  <input type="text" v-debounce:500="setSearch"
                         :placeholder="isPersonalCreditType ? locale.table.search_uin : locale.table.search_team">
                </label>
              </div>
              <div class="action-btn">
                <a @click.prevent="xmlsLink()" class="btn-download">{{ locale.table.download_XLS }}</a>
              </div>
              <div class="block-full">
                <span style="font-weight: 300;font-size: 16px; color: #949494;"
                      class="mr-2">{{ locale.table.full_mode }}</span>
                <button @click="setFullScreen" class="d-flex align-center">
                  <OpenFullIcon v-if="!full"/>
                  <CloseFullIcon v-else/>
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>


      <div class="table-extended__container">
        <div class="table-extended__block-table">
          <div class="tournament-table tournament-table--extended">
            <div class="scrollbar">
              <div class="scrollbar_container"></div>
            </div>


            <div class="tournament-table__container">


              <!-- Обычная таблица -->
              <table class="table-extended__table table-extended__table--main">
                <thead class="table-extended__thead">
                <tr class="table-extended__tr">
                  <template v-if="isPersonalCreditType">
                    <th rowspan="2" class="th-source-1">
                      <sort-by-component :name="locale.table.uin"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         sort-by="member.uin"
                                         @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.uin + 'asdas'"
                             v-debounce:500="(e) => setMember(e, 'member.uin')">
                    </th>
                    <th rowspan="2" class="th-source-2">
                      <sort-by-component :name="locale.table.name"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         sort-by="member.fullname"
                                         @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.name + 'asd'"
                             v-debounce:500="(e) => setMember(e, 'member.fullname')">
                    </th>
                    <th rowspan="2">
                      <sort-by-component :name="locale.table.team_table"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         sort-by="member.team.teamable.name"
                                         @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.team_table"
                      v-debounce:500="(e) => setMember(e, 'member.team.teamable.name')">
                    </th>
                    <th rowspan="2">
                      <sort-by-component :name="locale.table.date"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         sort-by="member.birthday"
                                         @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.date" v-debounce:500="(e) => setMember(e, 'member.birthday')">
                    </th>
                    <th rowspan="2">
                      <sort-by-component :name="locale.table.gender"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         sort-by="member.gender.id"
                                         @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.gender" v-debounce:500="(e) => setMember(e, 'member.gender.name_ru')">
                    </th>
                    <th rowspan="2">
                      <sort-by-component :name="locale.table.age"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         sort-by="member.ageCategory.id"
                                         @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.age" v-debounce:500="(e) => setMember(e, 'member.ageCategory.name')">
                    </th>
                  </template>
                  <template v-else-if="isTeamAndPersonalCreditType">
                    <th rowspan="2">
                      <sort-by-component
                          :name="locale.table.team_table"
                          :sorted-by="filters.sortBy"
                          :sorted-desc="filters.sortDesc"
                          sort-by="team.teamable.name"
                          @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.team_table" v-debounce:500="(e) => setMember(e, 'team.teamable.name')">
                    </th>
                  </template>

                  <th colspan="2" class="pa-4">{{ locale.table.total_summary }}</th>
                  <th colspan="2" class="pa-4">{{ locale.table.total_summaryMid }}</th>

                  <th class="pa-4" colspan="3" v-for="exercise in exercisesList" :key="`head_${exercise.id}`">{{
                      language === 'ru' ? exercise.title_ru : exercise.title_en
                    }}
                  </th>


                </tr>
                <tr>
                  <th class="pa-2">
                    <sort-by-component :name="locale.table.score_summary"
                                       :sorted-by="filters.sortBy"
                                       :sorted-desc="filters.sortDesc"
                                       sort-by="points_sum"
                                       @sort="sortBy"></sort-by-component>
                    <input :placeholder="locale.table.search + ' ' + locale.table.score_summary" 
                    v-debounce:500="(e) => setMember(e, 'points_sum')"
                    />
                  <th class="pa-2">
                    <sort-by-component :name="locale.table.place"
                                       :sorted-by="filters.sortBy"
                                       :sorted-desc="filters.sortDesc"
                                       sort-by="summary_place"
                                       @sort="sortBy"></sort-by-component>
                    <input :placeholder="locale.table.search + ' ' + locale.table.place"
                    v-debounce:500="(e) => setMember(e, 'summary_place')"/>

                  <th class="pa-2">
                    <sort-by-component :name="locale.table.score_count"
                                       :sorted-by="filters.sortBy"
                                       :sorted-desc="filters.sortDesc"
                                       sort-by="average_sum"
                                       @sort="sortBy"></sort-by-component>
                    <input :placeholder="locale.table.search + ' ' + locale.table.score_count"
                     v-debounce:500="(e) => setMember(e, 'average_sum')"/>
                  <th class="pa-2">
                    <sort-by-component :name="locale.table.place"
                                       :sorted-by="filters.sortBy"
                                       :sorted-desc="filters.sortDesc"
                                       sort-by="average_place"
                                       @sort="sortBy"></sort-by-component>
                    <input :placeholder="locale.table.search + ' ' + locale.table.place"
                     v-debounce:500="(e) => setMember(e, 'average_place')">
                  </th>

                  <template v-for="exercise in exercisesList">
                    <th class="pa-2" :key="`subhead_${exercise.id}_result`">
                      <sort-by-component :name="locale.table.result"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         :sort-by="`${exercise.id}_result`"
                                         @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.result"
                       v-debounce:500="(e) => setExercise(e, exercise.id, 'result')">
                    </th>
                    <th class="pa-2" :key="`subhead_${exercise.id}_points`">
                      <sort-by-component :name="locale.table.score"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         :sort-by="`${exercise.id}_points`"
                                         @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.score"
                       v-debounce:500="(e) => setExercise(e, exercise.id, 'points')">
                    </th>
                    <th class="pa-2" :key="`subhead_${exercise.id}_place`">
                      <sort-by-component :name="locale.table.place"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         :sort-by="`${exercise.id}_place`"
                                         @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.place"
                       v-debounce:500="(e) => setExercise(e, exercise.id, 'place')">
                    </th>
                  </template>

                </tr>
                </thead>

                <thead style="display: none" class="table-extended__thead table-extended__thead--fixed">
                <tr class="table-extended__tr">
                  <template v-if="isPersonalCreditType">
                    <th rowspan="2" class="th-receiver-1">
                      <sort-by-component :name="locale.table.uin"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         sort-by="member.uin"
                                         @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.uin"
                             v-debounce:500="(e) => setMember(e, 'member.uin')">
                    </th>
                    <th rowspan="2" class="th-receiver-2">
                      <sort-by-component :name="locale.table.name"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         sort-by="member.fullname"
                                         @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.name"
                             v-debounce:500="(e) => setMember(e, 'member.fullname')">
                    </th>
                    <th style="padding-left: 88px" rowspan="2">
                      <sort-by-component :name="locale.table.team_table"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         sort-by="member.team.teamable.name"
                                         @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.team_table"
                      v-debounce:500="(e) => setMember(e, 'member.team.teamable.name')">
                    </th>
                    <th rowspan="2">
                      <sort-by-component :name="locale.table.date"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         sort-by="member.birthday"
                                         @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.date"
                       v-debounce:500="(e) => setMember(e, 'member.birthday')">
                    </th>
                    <th rowspan="2">
                      <sort-by-component :name="locale.table.gender"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         sort-by="member.gender.id"
                                         @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.gender"
                       v-debounce:500="(e) => setMember(e, 'member.gender.name_ru')">
                    </th>
                    <th rowspan="2">
                      <sort-by-component :name="locale.table.age"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         sort-by="member.ageCategory.id"
                                         @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.age"
                      v-debounce:500="(e) => setMember(e, 'member.ageCategory.name')">
                    </th>
                  </template>
                  <template v-else-if="isTeamAndPersonalCreditType">
                    <th rowspan="2">
                      <sort-by-component
                          :name="locale.table.team_table"
                          :sorted-by="filters.sortBy"
                          :sorted-desc="filters.sortDesc"
                          sort-by="team.teamable.name"
                          @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.team_table"
                       v-debounce:500="(e) => setMember(e, 'team.teamable.name')">
                    </th>
                  </template>

                  <th colspan="2" class="pa-4">{{ locale.table.total_summary }}</th>
                  <th colspan="2" class="pa-4">{{ locale.table.score_summary }}</th>

                  <th class="pa-4" colspan="3" v-for="exercise in exercisesList" :key="`head_${exercise.id}`">{{
                      language === 'ru' ? exercise.title_ru : exercise.title_en
                    }}
                  </th>


                </tr>
                <tr>
                  <th class="pa-2">
                    <sort-by-component :name="locale.table.score_summary"
                                       :sorted-by="filters.sortBy"
                                       :sorted-desc="filters.sortDesc"
                                       sort-by="points_sum"
                                       @sort="sortBy"></sort-by-component>
                    <input :placeholder="locale.table.search + ' ' + locale.table.score_summary"
                     v-debounce:500="(e) => setMember(e, 'points_sum')"></th>
                  <th class="pa-2">
                    <sort-by-component :name="locale.table.place"
                                       :sorted-by="filters.sortBy"
                                       :sorted-desc="filters.sortDesc"
                                       sort-by="summary_place"
                                       @sort="sortBy"></sort-by-component>
                    <input :placeholder="locale.table.search + ' ' + locale.table.place"
                    v-debounce:500="(e) => setMember(e, 'summary_place')"></th>

                  <th class="pa-2">
                    <sort-by-component :name="locale.table.score_count"
                                       :sorted-by="filters.sortBy"
                                       :sorted-desc="filters.sortDesc"
                                       sort-by="average_sum"
                                       @sort="sortBy"></sort-by-component>
                    <input :placeholder="locale.table.search + ' ' + locale.table.score_count"
                    v-debounce:500="(e) => setMember(e, 'average_sum')"></th>
                  <th class="pa-2">
                    <sort-by-component :name="locale.table.place"
                                       :sorted-by="filters.sortBy"
                                       :sorted-desc="filters.sortDesc"
                                       sort-by="average_place"
                                       @sort="sortBy"></sort-by-component>
                    <input :placeholder="locale.table.search + ' ' + locale.table.place"
                    v-debounce:500="(e) => setMember(e, 'average_place')">
                  </th>

                  <template v-for="exercise in exercisesList">
                    <th class="pa-2" :key="`subhead_${exercise.id}_result`">
                      <sort-by-component :name="locale.table.result"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         :sort-by="`${exercise.id}_result`"
                                         @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.result"
                      v-debounce:500="(e) => setExercise(e, exercise.id, 'result')">
                    </th>
                    <th class="pa-2" :key="`subhead_${exercise.id}_points`">
                      <sort-by-component :name="locale.table.score"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         :sort-by="`${exercise.id}_points`"
                                         @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.score"
                      v-debounce:500="(e) => setExercise(e, exercise.id, 'points')">
                    </th>
                    <th class="pa-2" :key="`subhead_${exercise.id}_place`">
                      <sort-by-component :name="locale.table.place"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         :sort-by="`${exercise.id}_place`"
                                         @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.place"
                      v-debounce:500="(e) => setExercise(e, exercise.id, 'place')">
                    </th>
                  </template>

                </tr>
                </thead>
                <thead style="display: none" class="table-extended__thead table-extended__thead--horizontal-fixed">

                <tr class="table-extended__tr">
                  <template v-if="isPersonalCreditType">
                    <th rowspan="2" class="th-receiver-1">
                      <sort-by-component :name="locale.table.uin"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         sort-by="member.uin"
                                         @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.uin"
                             v-debounce:500="(e) => setMember(e, 'member.uin')">
                    </th>
                    <th rowspan="2" class="th-receiver-2">
                      <sort-by-component :name="locale.table.name"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         sort-by="member.fullname"
                                         @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.name"
                             v-debounce:500="(e) => setMember(e, 'member.fullname')">
                    </th>
                  </template>
                  <template v-else-if="isTeamAndPersonalCreditType">
                    <th rowspan="2">
                      <sort-by-component
                          :name="locale.table.team_table"
                          :sorted-by="filters.sortBy"
                          :sorted-desc="filters.sortDesc"
                          sort-by="team.teamable.name"
                          @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.team_table"
                      v-debounce:500="(e) => setMember(e, 'team.teamable.name')">
                    </th>
                  </template>

                </tr>
                <tr>
                </tr>
                </thead>


                <tbody v-if="!table.loading" class="table-extended__tbody">
                <tr v-for="item in table.data.data" :key="item.id">

                  <template v-if="isPersonalCreditType">
                    <td>{{ item.member.uin ? item.member.uin : '-' }}</td>
                    <td>{{ item.member.fullname }}</td>
                    <td>{{ item.member.team ? item.member.team.teamable.name : '-' }}</td>
                    <td>{{ item.member.birthday|formatDate }}</td>
                    <td>{{ language === 'ru' ? item.member.gender.name_ru : item.member.gender.name_en }}</td>
                    <td>{{ item.member.age_category ? item.member.age_category.name : '-' }}</td>
                  </template>
                  <template v-else-if="isTeamAndPersonalCreditType">
                    <td>{{ item.team.teamable.name }}</td>
                  </template>

                  <td>{{ item.points_sum }}</td>
                  <td>{{ item.summary_place }}</td>

                  <td>{{ item.average_sum|numberFormat }}</td>
                  <td>{{ item.average_place }}</td>

                  <template v-for="exercise in exercisesList">
                    <td :key="`row_${item.id}_${exercise.id}_result`">
                      {{
                        (item.exercises[exercise.id] && isPersonalCreditType) ? item.exercises[exercise.id].value : '-'
                      }}
                    </td>
                    <td :key="`row_${item.id}_${exercise.id}_points`">
                      {{ item.exercises[exercise.id] ? item.exercises[exercise.id].points : '-' }}
                    </td>
                    <td :key="`row_${item.id}_${exercise.id}_place`">
                      {{ item.exercises[exercise.id] ? item.exercises[exercise.id].place : '-' }}
                    </td>
                  </template>

                </tr>
                </tbody>

                <tbody v-else>
                <tr>
                  <td colspan="100">Подождите, загружаю таблицу</td>
                </tr>
                </tbody>
              </table>

              <!-- Фиксированная таблица для прокрутки влево -->
              <table class="table-extended__table table-extended__table--fixed">
                <thead class="table-extended__thead">
                <tr class="table-extended__tr">
                  <template v-if="isPersonalCreditType">
                    <th rowspan="2" class="th-receiver-1">
                      <sort-by-component :name="locale.table.uin"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         sort-by="member.uin"
                                         @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.uin"
                             v-debounce:500="(e) => setMember(e, 'member.uin')">
                    </th>
                    <th rowspan="2" class="th-receiver-2">
                      <sort-by-component :name="locale.table.name"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         sort-by="member.fullname"
                                         @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.name"
                             v-debounce:500="(e) => setMember(e, 'member.fullname')">
                    </th>
                  </template>
                  <template v-else-if="isTeamAndPersonalCreditType">
                    <th rowspan="2">
                      <sort-by-component
                          :name="locale.table.team_table"
                          :sorted-by="filters.sortBy"
                          :sorted-desc="filters.sortDesc"
                          sort-by="team.teamable.name"
                          @sort="sortBy"></sort-by-component>
                      <input :placeholder="locale.table.search + ' ' + locale.table.team_table"
                      v-debounce:500="(e) => setMember(e, 'team.teamable.name')">
                    </th>
                  </template>

                </tr>
                <tr>
                </tr>
                </thead>

                <tbody v-if="!table.loading" class="table-extended__tbody">
                <tr v-for="item in table.data.data" :key="item.id">

                  <template v-if="isPersonalCreditType">
                    <td v-if="isPersonalCreditType && item.member">{{ item.member.uin ? item.member.uin : '-' }}</td>
                    <td v-if="isPersonalCreditType && item.member">{{ item.member.fullname }}</td>
                  </template>
                  <template v-else-if="isTeamAndPersonalCreditType">
                    <td>{{ item.team.teamable.name }}</td>
                  </template>
                </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
        <div class="d-flex justify-space-between align-center">
          <div class="tournament-table__pagination" v-if="table.data.last_page > 1">
            <v-pagination
                v-model="filters.page"
                class="my-4"
                color="var(--extra_color)"
                :length="table.data.last_page"
                :total-visible="7"
            ></v-pagination>
          </div>
          <div class="d-flex align-center ml-auto">
            {{ locale.table.show }}
            <v-select
                style="maxWidth: 82px;"
                v-model="filters.limit"
                outlined
                :items="pageSelectItems"

                class="ml-2"
                item-text="value"
                hide-details
                item-value="value"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {api} from '../../api/api';
import TablesNavigation from './components/tables-navigation'
import SortByComponent from './components/sort-by-component'
import OpenFullIcon from './OpenFullIcon.vue'
import CloseFullIcon from './CloseFullIcon.vue'


export default ({
  components: {
    TablesNavigation,
    SortByComponent,
    OpenFullIcon,
    CloseFullIcon,
  },
  data() {
    return ({
      festival: null,
      table: {
        data: [],
        loading: true
      },
      full: false,
      filters: {},
      exercisesList: [],
      ageCategoriesList: [],
      teamsList: [],
      pageSelectItems: [
        {value: '10'},
        {value: '20'},
        {value: '50'},
      ],
      hrefXLS: null,
    })
  },
  async created() {
    this.fetch()


    await this.setDefaultFilters()
  },
  mounted() {


    window.addEventListener('scroll', function () {
      let scrollbar = document.querySelector('.scrollbar')
      let table = document.querySelector('.tournament-table--extended');
      if (table) {
        let theadFixed = document.querySelector('.table-extended__thead--fixed');
        let fixedTable = document.querySelector('.table-extended__table--fixed');
        let theadHorizontalFixed = document.querySelector('.table-extended__thead--horizontal-fixed');

        //Выравниваем ячейки по источнику
        //Источник у нас главная таблица с правильно отрендериными столбацми


        //theadFixed.querySelector('th').style['paddingLeft'] =

        let rect = table.getBoundingClientRect();

        const mobileWidth = 1030
        const topPaddingMobile = 76
        const topPaddingDesktop = 86

        const isMobile = window.innerWidth <= mobileWidth
        const topPadding = isMobile ? topPaddingMobile : topPaddingDesktop

        if (rect.top < topPadding) {
          scrollbar.style['position'] = 'fixed';
          scrollbar.style['top'] = `${topPadding - 1}px`;
          scrollbar.style['width'] = getComputedStyle(table).width;

          theadFixed.style['display'] = 'block';
          theadFixed.style['width'] = getComputedStyle(table).width;

          fixedTable.style['top'] = '0px'

          theadHorizontalFixed.style['display'] = 'block';

        } else {
          scrollbar.style['position'] = 'unset';
          scrollbar.style['top'] = '0';
          scrollbar.style['width'] = '100%';
          theadFixed.style['display'] = 'none';

          fixedTable.style['top'] = '15px'

          theadHorizontalFixed.style['display'] = 'none';
        }
      }
    });

  },
  watch: {
    filters: {
      handler() {
        this.fetch()
      },
      deep: true
    },
  },
  updated() {
    document.querySelectorAll('.th-receiver-1').forEach((e) => {
      let source = document.querySelector('.th-source-1');
      e.style['width'] = getComputedStyle(source).width
      e.style['height'] = getComputedStyle(source).height
    })

    document.querySelectorAll('.th-receiver-2').forEach((e) => {
      let source = document.querySelector('.th-source-2');
      e.style['width'] = getComputedStyle(source).width
      e.style['height'] = getComputedStyle(source).height
    })

    //let tableFixed = document.querySelector('.table-extended__thead--fixed');

    //tableFixed.querySelector('th').style['paddingLeft'] = '100px'

  },
  computed: {
    ...mapGetters({
      locale: 'lang/locale',
      isDesktop: 'app/isDesktop',
      isMobile: 'app/isMobile',
      language: 'lang/language',
      festivalInfo: 'desk/festivalInfo',
    }),
    isSummaryRating() {
      return this.filters.rating === 'summary'
    },
    isAverageRating() {
      return this.filters.rating === 'average'
    },
    isPersonalCreditType() {
      return this.filters.credit_type === 'personal'
    },
    isTeamAndPersonalCreditType() {
      return this.filters.credit_type === 'team_and_personal'
    }
  },
  methods: {
    sortBy(field) {
      if (this.filters.sortBy === field) {
        this.filters.sortDesc = !this.filters.sortDesc
      } else {
        this.filters.sortDesc = false;
      }
      this.filters.sortBy = field;
    },
    switchCreditType(type) {
      this.setDefaultFilters();
      this.filters.credit_type = type
    },
    setFullScreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen();
        this.full = false;
      } else {
        const table = document.querySelector('.table-extended');
        table.requestFullscreen();
        this.full = true;
      }
    },
    setDefaultFilters() {
      this.filters = {
        credit_type: 'personal',
        rating: 'summary',
        page: 1,
        genders: ['1', '2'],
        teams: [],
        age_categories: [],
        exercises: [],
        search: null,
        limit: '10',
        sortBy: 'member.uin',
        sortDesc: false,
        extended: true,
        extendedSearch: {
          'member.uin': null,
          'member.fullname': null,
          'member.team.teamable.name': null,
          'team.teamable.name': null,
          'member.birthday': null,
          'member.gender.name': null,
          'member.ageCategory.name': null,
          'member.gender.name_ru': null,
          points_sum: null,
          summary_place: null,
          average_sum: null,
          average_place: null
        }
      }
    },
    async xmlsLink() {
      try {
        let xmlsLink = await api.tournamentTable.export(this.$route.params.slug, this.filters)
        const link = document.createElement('a');
        link.href = xmlsLink.data.link;
        link.download = 'tournament-table.xlsx';
        link.click();
      } catch (e) {
        console.error(e)
      }
    },
    setSearch(e) {
      this.filters.search = e
    },
    setMember(value, name){
      this.filters.extendedSearch[`${name}`] = value
    },
    setExercise(value, id, tag) {
      this.filters.extendedSearch[`${id}_${tag}`] = value
    },
    async fetch() {
      this.table.loading = true;

      let response = await api.tournamentTable.get(this.$route.params.slug, this.filters)

      if (!this.festival) {
        this.festival = response.data.festival;
        this.prepareFilters()
      }

      this.table.data = response.data.table;

      this.table.loading = false;

      let scrollbar = document.querySelector('.scrollbar');
      let table = document.querySelector('.tournament-table__container');
      let theadFixed = document.querySelector('.table-extended__thead--fixed');
      let fixedTable = document.querySelector('.table-extended__table--fixed');
      let mainTable = document.querySelector('.table-extended__table--main');

      let mainTableThs = mainTable.querySelectorAll('thead th')
      let fixedTableThs = fixedTable.querySelectorAll('thead th')

      for (let i = 0; i < fixedTableThs.length; i++) {
        fixedTableThs[i].style['width'] = parseFloat(getComputedStyle(mainTableThs[i]).width)
      }


      scrollbar.onscroll = function () {
        table.scrollLeft = scrollbar.scrollLeft;
        theadFixed.scrollLeft = scrollbar.scrollLeft;

      };

      new ResizeObserver(() => {
        this.makeScrollbar();
      }).observe(table);

      //Пример получения xlsx
    },
    prepareFilters() {
      let exercises = new Map();
      this.festival.exercises.map(exercise => {
        exercises.set(exercise.exercise.id, {
          id: exercise.exercise.id,
          title_ru: exercise.exercise.title_ru,
          title_en: exercise.exercise.title_en
        })
      })

      this.exercisesList = Array.from(exercises.values());

      this.exercisesList.forEach(el => {
        this.$set(this.filters.extendedSearch,`${el.id}_result`, null )
        this.$set(this.filters.extendedSearch,`${el.id}_points`, null )
        this.$set(this.filters.extendedSearch,`${el.id}_place`, null )
      })

      let ageCategories = new Map();
      this.festival.exercises.map(exercise => {
        exercise.ageCategories.list.map(x => {
          ageCategories.set(x.id, {id: x.id, text: `${x.from} - ${x.to}`})
        })
      });

      this.teamsList = this.festival.teams.list.map(x => {
        return {id: x.id, text: x.teamable.name}
      });

      this.ageCategoriesList = Array.from(ageCategories.values()).sort((a, b) => {
        if (a.id < b.id) return -1
        return a.id > b.id ? 1 : 0
      });
    },
    makeScrollbar() {
      let scrollbar = document.querySelector('.scrollbar_container')
      let tableWidth = getComputedStyle(document.querySelector('.tournament-table__container table')).width;
      scrollbar.style.width = tableWidth
    },
  }
})
</script>

<style lang="scss">

@import "../../styles/tournament-table";

.scrollbar::-webkit-scrollbar {
  width: 12px;
}

.scrollbar::-webkit-scrollbar-track {
  background: #fff;
  border-top: 1px solid var(--extra_color);
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--extra_color);
}

.table-extended {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &__table {
    &--fixed {
      position: absolute;
      top: 15px;
      z-index: 1000;

      @media screen and(max-width: 1030px) {
        display: none
      }

      tbody {

        td {
          padding: 16px 0px 13px 10px;
          text-align: start;
          border-top: 0px;
          border-bottom: 0px;
          border-left: 0px;
          border-right: 0px;
          white-space: nowrap;
        }
      }

      th {
        padding-bottom: 9px !important;
        padding-top: 4px !important;
      }
    }
  }

  &__thead {
    &--fixed {
      position: fixed;
      top: 100px;
      display: none;
      width: 100%;
      overflow-x: scroll;

      @media screen and (max-width: 1030px) {
        top: 90px;
      }

      &::-webkit-scrollbar {
        display: none;
      }

    }

    &--horizontal-fixed {
      z-index: 1001;
      position: fixed;
      top: 100px;
      display: none;

      @media screen and (max-width: 1030px) {
        top: 90px;
      }

      th {
        padding-bottom: 9px !important;
        padding-top: 4px !important;
      }
    }
  }

  .block-actions {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .action-btn {
      margin-left: 20px;
      display: flex;
      align-items: center;
    }

    .block-full {
      display: flex;
      align-items: center;
      margin-left: auto;
    }

    @media screen and (max-width: 1030px) {
      flex-direction: column;
      align-items: flex-start;

      .header-search {
        width: 100%;
      }
      .action-btn {
        margin: 16px 0;
        &, .btn-download {
          width: 100%;
        }
      }
      .block-full {
        display: none;
      }
    }
  }

  &.full {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    overflow: scroll;
    margin-bottom: 0;
  }

  .btn-download {
    padding: 10px 34px 11px 30px;

    text-decoration: none;
    font-size: 16px;

    color: #FFFFFF;
    background: var(--extra_color);
  }

  &__table {
    border-spacing: 0px;
  }


  &__thead {

    th {
      background: #ffff;
    }

    .table-extended__tr {
      th {
        background: #ffff;

        span {
          display: flex;
          align-items: baseline;
        }

        .sorter {
          margin-top: 24px;
          margin-bottom: 37px;
        }


        text-align: start;
        padding-bottom: 5px;

        &:nth-child(1) {
          border-left: 0px;
          border-right: 0px;
        }

        &:nth-child(2) {
          border-left: 0px;
          border-right: 0px;
        }

        &:nth-child(3) {
          border-left: 0px;
          border-right: 0px;
        }

        &:nth-child(4) {
          border-left: 0px;
          border-right: 0px;
        }

        &:nth-child(5) {
          border-left: 0px;
          border-right: 0px;
        }

        &:nth-child(6) {
          border-left: 0px;
          border-right: 0px;

          span {
            text-align: start;
          }
        }

        input {
          max-width: 80%;
          border: 1px solid gray;
          border-radius: 5px;
        }
      }
    }

    tr {
      th {
        border-color: var(--extra_color);

        font-weight: 400;

        text-align: start;

        input {
          max-width: 80%;
          border: 1px solid gray;
          border-radius: 5px;
        }
      }
    }
  }

  &__tbody {
    tr {
      td {
        padding: 16px 0px 13px 10px;

        text-align: start;

        border-top: 0px;
        border-bottom: 0px;
        border-left: 0px;
        border-right: 0px;
        white-space: nowrap;

        &:nth-child(1) {
          padding-left: 21px ;
        }
      }

      &:nth-child(odd) {
        background: #EFEFEF;
      }

      &:nth-child(even) {
        background: #ffffff;
      }
    }
  }

  margin-bottom: 30px;

  &__container {
    width: 100%;
    max-width: 1500px;
    position: relative;

    &:before {
      content: "";
      top: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      width: 200px;
      background: linear-gradient(to right, rgba(255, 255, 58, 0) 0%, rgba(255, 255, 255, 1) 100%);
      pointer-events: none;
    }
  }
}
</style>