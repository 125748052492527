<template>
  <div class="table" v-if="festivalInfo">
    <TablesNavigation></TablesNavigation>
    <div class="table-compact">
      <div class="table-compact__container">
        <div class="table-compact__tabs-view">
          <div class="table-compact__tabs-view--items">
            <div class="table-compact__tabs-view--items-title">
              {{ locale.table.type }}:
            </div>
            <div class="table-compact__tabs-view--items-item mr-1" :class="{ active: isPersonalCreditType}"
                 @click="switchCreditType('personal')">
              {{ locale.table.individual }}
            </div>
            <div class="table-compact__tabs-view--items-item ml-1"
                 v-if="festivalInfo.settings.credit_type === 'team_and_personal'"
                 :class="{ active: isTeamAndPersonalCreditType}"
                 @click="switchCreditType('team_and_personal')">/
              {{ locale.table.team }}
            </div>
          </div>
        </div>
        <div class="table-compact__blocks">
          <div class="table-compact__block-table">
            <div class="block-table__header">
              <div class="header-search">
                <label>
                  <input v-debounce:500="setSearch" type="text"
                         :placeholder="isPersonalCreditType ? locale.table.search_uin : locale.table.search_team">
                </label>
              </div>
              <div class="header-tabs-raiting">
                <div class="header-tabs-raiting--items">
                  <div class="header-tabs-raiting--items-title">
                    {{ locale.table.rating }}:
                  </div>
                  <div class="header-tabs-raiting--items-item" :class="{ active: isSummaryRating}"
                       @click="filters.rating = 'summary'">
                    {{ locale.table.summary }}
                  </div>
                  <div class="header-tabs-raiting--items-item" :class="{ active: isAverageRating}"
                       @click="filters.rating = 'average'">
                    {{ locale.table.average }}
                  </div>
                </div>
              </div>
            </div>
            <div class="block-table tournament-table--compact">
              <div class="tournament-table__container">
                <table class="tournament-table no-spacing">
                  <thead class="block-table__thead">
                  <tr>
                    <th>
                      <sort-by-component :name="locale.table.place"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         sort-by="place"
                                         @sort="sortBy"></sort-by-component>
                    </th>
                    <template v-if="isPersonalCreditType">
                      <th>
                        <sort-by-component :name="locale.table.uin"
                                           :sorted-by="filters.sortBy"
                                           :sorted-desc="filters.sortDesc"
                                           sort-by="member.uin"
                                           @sort="sortBy"></sort-by-component>
                      </th>
                      <th>
                        <sort-by-component :name="locale.table.name"
                                           :sorted-by="filters.sortBy"
                                           :sorted-desc="filters.sortDesc"
                                           sort-by="member.fullname"
                                           @sort="sortBy"></sort-by-component>
                      </th>
                      <th>
                        <sort-by-component :name="locale.table.gender"
                                           :sorted-by="filters.sortBy"
                                           :sorted-desc="filters.sortDesc"
                                           sort-by="member.gender.id"
                                           @sort="sortBy"></sort-by-component>
                      </th>
                      <th>
                        <sort-by-component :name="locale.table.age"
                                           :sorted-by="filters.sortBy"
                                           :sorted-desc="filters.sortDesc"
                                           sort-by="member.ageCategory.id"
                                           @sort="sortBy"></sort-by-component>
                      </th>
                    </template>
                    <th v-if="isTeamAndPersonalCreditType">
                      <sort-by-component :name="locale.table.team"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         sort-by="team.teamable.name"
                                         @sort="sortBy"></sort-by-component>
                    </th>

                    <template v-if="filters.exercises.length > 0 && showCols">
                      <template v-for="exerciseid in filters.exercises">
                        <th class="exercise_cell"
                            :key="`subhead_${exerciseid}_points`">
                            <sort-by-component :name="`${getExerciseTitle(exerciseid)} ${locale.table.score}`"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         :sort-by="`${exerciseid}_points`"
                                         @sort="sortBy"></sort-by-component>
                        </th>
                        <th class="exercise_cell" :key="`subhead_${exerciseid}_result`"
                            v-if="!isTeamAndPersonalCreditType">
                            <sort-by-component :name="`${getExerciseTitle(exerciseid)} ${locale.table.result}`"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         :sort-by="`${exerciseid}_result`"
                                         @sort="sortBy"></sort-by-component>
                        </th>
                      </template>
                    </template>
                    <template v-if="(filters.exercises.length === 0 || filters.exercises.length > 1)">
                      <th>
                    <span v-if="isSummaryRating">
                      <sort-by-component :name="locale.table.summary"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         sort-by="points_sum"
                                         @sort="sortBy"></sort-by-component>
                    </span>
                        <span v-else-if="isAverageRating">
                      <sort-by-component :name=" locale.table.average"
                                         :sorted-by="filters.sortBy"
                                         :sorted-desc="filters.sortDesc"
                                         sort-by="average_sum"
                                         @sort="sortBy"></sort-by-component></span>
                      </th>
                    </template>


                  </tr>
                  </thead>
                  <tbody class="block-table__tbody" v-if="!table.loading">
                  <tr v-for="item in table.data.data" :key="`item_${item.place}`">
                    <td>{{ item.place }}</td>
                    <template v-if="isPersonalCreditType && item.member">
                      <td>{{ item.member.uin }}</td>
                      <td>{{ item.member.fullname }}</td>
                      <td>{{ language === 'ru' ? item.member.gender.name_ru : item.member.gender.name_en }}</td>
                      <td>{{ item.member.age_category ? item.member.age_category.name : '-' }}</td>
                    </template>

                    <td v-if="isTeamAndPersonalCreditType &&  item.team">{{ item.team.teamable.name }}</td>

                    <template v-if="filters.exercises.length > 0 && showCols">
                      <template v-for="exerciseid in filters.exercises">
                        <td :key="`${exerciseid}_points`">
                          {{ item.exercises[exerciseid].points }}
                        </td>
                        <td :key="`${exerciseid}_result`" v-if="!isTeamAndPersonalCreditType">
                          {{ item.exercises[exerciseid].value }}
                        </td>
                      </template>
                    </template>
                    <template v-if="filters.exercises.length === 0 || filters.exercises.length > 1">
                      <td>{{ ((isSummaryRating) ? item.points_sum : item.average_sum)|numberFormat }}</td>
                    </template>

                  </tr>


                  </tbody>

                  <tbody v-else>
                  <tr>
                    <td colspan="20">{{ locale.table.loading }}</td>
                  </tr>
                  </tbody>

                </table>
              </div>
            </div>
            <div class="table-compact__sort">
              <div class="tournament-table__pagination" v-if="table.data.last_page > 1">
                <v-pagination
                    v-model="filters.page"
                    class="my-4"
                    color="var(--extra_color)"
                    :length="table.data.last_page"
                    :total-visible="7"
                />
              </div>
              <div class="d-flex align-center ml-auto">
                {{ locale.table.show }}
                <v-select
                    style="maxWidth: 82px;"
                    v-model="filters.limit"
                    outlined
                    :items="pageSelectItems"
                    class="ml-2"
                    item-text="value"
                    hide-details
                    item-value="value"
                />
              </div>
            </div>
          </div>
          <div class="table-compact__block-filter">
            <div class="block-filter--sex">
              <div class="block-filter--sex-title">{{ locale.trial.labels.sex }}</div>
              <div class="block-filter--sex-radio">
                <v-radio-group
                    :row="isDesktop"
                    :column="isMobile"
                    readonly
                >
                  <v-checkbox
                      color="var(--extra_color)"
                      :label="locale.trial.male"
                      v-model="filters.genders"
                      hide-details
                      value="1"
                  ></v-checkbox>
                  <v-checkbox
                      color="var(--extra_color)"
                      :label="locale.trial.female"
                      v-model="filters.genders"
                      hide-details
                      value="2"
                  ></v-checkbox>
                </v-radio-group>
              </div>
            </div>
            <v-divider color="#BB9657"/>
            <div class="block-filter--age">
              <div class="block-filter--age-title">
                {{ locale.trial.age }}
              </div>
              <div class="block-filter--age-radio">
                <v-checkbox
                    v-for="ageCategory in ageCategoriesList" :key="`category_${ageCategory.id}`"
                    color="var(--extra_color)"
                    :label="ageCategory.text"
                    hide-details
                    v-model="filters.age_categories"
                    :value="ageCategory.id"
                ></v-checkbox>

              </div>
            </div>
            <v-divider color="#BB9657"/>
            <div class="block-filter--commands" v-if="isTeamAndPersonalCreditType">
              <div class="block-filter--commands-title">
                {{ locale.table.commands }}
              </div>
              <div class="block-filter--commands-select">
                <v-select
                    dense
                    outlined
                    item-text="text"
                    item-value="id"
                    multiple
                    :placeholder="locale.placeholders.select_teams"
                    :items="teamsList"
                    v-model="filters.teams"
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                        ripple
                        @click="toggleTeamsAll"
                    >
                      <v-list-item-action>
                        <v-icon>
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ locale.buttons.sellect_all }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
              </div>
            </div>
            <v-divider color="#BB9657"/>
            <div class="block-filter--competition">
              <div class="block-filter--competition-title">
                {{ locale.table.competition }}
              </div>
              <div class="block-filter--competition-select">
                <v-select
                    dense
                    outlined
                    :placeholder="locale.placeholders.select_exercises"
                    :item-text="language === 'ru' ? 'text_ru' : 'text_en'"
                    item-value="id"
                    multiple
                    item-color="#BB9657"
                    :items="exercisesList"
                    v-model="filters.exercises"
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                        ripple
                        @click="toggleExercisesAll"
                    >
                      <v-list-item-action>
                        <v-icon>
                          {{ iconExercise }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ locale.buttons.sellect_all }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
              </div>
            </div>
            <v-divider color="#BB9657"/>
            <div class="block-filter--actions">
              <button class="reset-btn" @click="setDefaultFilters">{{ locale.buttons.reset_filter }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {api} from '../../api/api';
import TablesNavigation from './components/tables-navigation'
import SortByComponent from './components/sort-by-component'

export default ({
  components: {
    TablesNavigation,
    SortByComponent,
  },
  data() {
    return ({
      festival: null,
      table: {
        data: [],
        loading: true
      },
      filters: {},
      exercisesList: [],
      ageCategoriesList: [],
      teamsList: [],
      currentPage: 1,
      pageSelectItems: [
        {value: '10'},
        {value: '20'},
        {value: '50'},
      ]
    })
  },
  created() {
    this.setDefaultFilters();
  },

  computed: {
    ...mapGetters({
      locale: 'lang/locale',
      isDesktop: 'app/isDesktop',
      isMobile: 'app/isMobile',
      language: 'lang/language',
      festivalInfo: 'desk/festivalInfo',
    }),
    isAllTeams() {
      if (this.filters && this.filters.teams && this.festival) {
        return this.filters.teams.length === this.festival.teams.list.length;
      }
      return false;
    },
    isAllExercise() {
      if (this.filters && this.exercisesList && this.festival) {
        return this.filters.exercises.length === this.exercisesList.length;
      }
      return false;
    },
    showCols() {
      return this.filters.exercises.length !== this.exercisesList.length
    },
    isSummaryRating() {
      return this.filters.rating === 'summary'
    },
    isAverageRating() {
      return this.filters.rating === 'average'
    },
    isPersonalCreditType() {
      return this.filters.credit_type === 'personal'
    },
    isTeamAndPersonalCreditType() {
      return this.filters.credit_type === 'team_and_personal'
    },
    isLevels_and_groups() {
      return this.festivalInfo.settings.age_level_display === 'levels_and_groups';
    },
    iconExercise() {
      if (this.isAllExercise) return 'mdi-close-box'
      return 'mdi-checkbox-blank-outline'
    },
    icon() {
      if (this.isAllTeams) return 'mdi-close-box'
      return 'mdi-checkbox-blank-outline'
    },
  },
  watch: {
    filters: {
      handler() {
        this.fetch()
      },
      deep: true
    },
  },
  methods: {
    switchCreditType(type) {
      this.setDefaultFilters();
      this.filters.credit_type = type
    },
    setDefaultFilters() {
      this.filters = {
        credit_type: 'personal',
        rating: 'summary',
        page: 1,
        genders: ['1', '2'],
        teams: [],
        age_categories: [],
        exercises: [],
        search: null,
        limit: '10',
        sortBy: 'place',
        sortDesc: false
      }
    },
    toggleTeamsAll() {
      this.$nextTick(() => {
        if (this.isAllTeams) {
          this.filters.teams = [];
        } else {
          this.filters.teams = [...this.festival.teams.list.map(x => {
            return x.id
          })]
        }
      })
    },
    toggleExercisesAll() {
      this.$nextTick(() => {
        if (this.isAllExercise) {
          this.filters.exercises = [];
        } else {
          this.filters.exercises = [...this.exercisesList.map(x => {
            return x.id
          })]
        }
      })
    },
    sortBy(field) {
      if (this.filters.sortBy === field) {
        this.filters.sortDesc = !this.filters.sortDesc
      } else {
        this.filters.sortDesc = false;
      }
      this.filters.sortBy = field;

    },
    setSearch(e) {
      this.filters.search = e
    },
    async fetch() {

      this.table.loading = true;

      let age_categories = []

      this.filters.age_categories.forEach((item) => {
        if (item instanceof Array) {
          age_categories = age_categories.concat(item)
        } else {
          age_categories.push(item)
        }
      });


      let response = await api.tournamentTable.get(this.$route.params.slug, {
        credit_type: this.filters.credit_type,
        rating: this.filters.rating,
        page: this.filters.page,
        genders: this.filters.genders,
        teams: this.filters.teams,
        age_categories: age_categories,
        exercises: this.filters.exercises,
        search: this.filters.search,
        limit: this.filters.limit,
        sortBy: this.filters.sortBy,
        sortDesc: this.filters.sortDesc
      })

      if (!this.festival) {
        this.festival = response.data.festival;
        this.prepareFilters();
      }

      this.table.data = response.data.table;

      this.table.loading = false;
    },
    getExerciseTitle(id) {
      const exercise = this.exercisesList.find(x => x.id === id);
      const exerciseTitle = exercise[`text_${this.language}`]

      return exerciseTitle
    },
    prepareFilters() {
      let exercises = new Map();
      this.festival.exercises.map(exercise => {
        exercises.set(exercise.exercise.id, {id: exercise.exercise.id, text_ru: exercise.exercise.title_ru, text_en: exercise.exercise.title_en})
      })

      this.exercisesList = Array.from(exercises.values());

      this.teamsList = this.festival.teams.list.map(x => {
        return {id: x.id, text: x.teamable.name}
      });


      this.ageCategoriesList = this.isLevels_and_groups ? this.sortCheckBoxes(this.prepareAgeCheckBoxesDefault) : Array.from(this.prepareAgeCheckBoxesLevelsAndGroups().values());
    },
    prepareAgeCheckBoxesLevelsAndGroups() {
      let ageCategories = new Map();
      let items = [];
      this.festival.exercises.forEach(exercise => {
        const ageList = exercise.ageCategories.list;
        ageList.forEach((x) => {
          for (let i = 0; i < ageList.length; i++) {
            if (x.name === ageList[i].name) {
              items.push(ageList[i])
            }
          }

          ageCategories.set(items[0].id, {
            id: [...items.map(el => el.id)],
            text: `${items[0].name}(${items[0].from} - ${items[items.length - 1].to})`
          })
          items = [];
        })
      });

      return ageCategories
    },
    sortCheckBoxes(arr) {
      const items = Array.from(arr().values()).sort((a, b) => {
        if (a.id < b.id) return -1
        return a.id > b.id ? 1 : 0
      })

      return items
    },
    prepareAgeCheckBoxesDefault() {
      let ageCategories = new Map();
      this.festival.exercises.map(exercise => {
        exercise.ageCategories.list.map(x => {
          ageCategories.set(x.id, {id: x.id, text: `${x.name}(${x.from} - ${x.to})`})
        })
      });

      return ageCategories;
    },
  }
})
</script>

<style lang="scss">
@import "../../styles/tournament-table";

.v-pagination__item {
  box-shadow: none !important;
  border: 1px solid black !important;
}

.v-list-item__title, .v-list-item__subtitle {
  white-space: pre-wrap !important;
}

.theme--light.v-pagination .v-pagination__item--active {
  border: none !important;
}

.v-pagination__navigation {
  box-shadow: none !important;
}

.table-compact {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .v-list-item__title {
    text-align: end;
  }

  &__sort {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (max-width: 768px) {


      .tournament-table__pagination {
        nav {
          flex-wrap: wrap;
        }
      }
    }
  }

  &__container {
    width: 100%;
    max-width: 1500px;
  }

  &__tabs-view {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 30px;

    &--items {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      max-width: 1500px;

      &-title {
        margin-right: 15px;

        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        color: #949494;
      }

      &-item {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        font-weight: 300;
        cursor: pointer;
        color: #3C3C3C;

        &.active {
          box-shadow: 0 3px 0px -1px var(--extra_color);
          font-weight: normal;
        }
      }
    }
  }

  &__blocks {
    display: grid;
    grid-auto-flow: column;
    margin-top: 30px;
    grid-template-columns: 52% 45%;
    gap: 30px;
    overflow: hidden;
    margin-top: 30px;

    @media screen and (max-width: 768px) {
      grid-auto-flow: row;
      grid-template-columns: 100%;
    }
  }

  &__block-table {
    flex: 2;

    @media screen and (max-width: 768px) {
      width: 100%;
    }


    .block-table {
      overflow-x: auto;
    }

    .no-spacing {
      border-spacing: 0px;

      .block-table__thead {
        tr {
          th {
            border-top: 1px solid var(--extra_color);
            border-bottom: 1px solid var(--extra_color);
            border-left: 0px;
            border-right: 0px;
            text-align: start;

            font-weight: 400;

            padding: 8px 0px;

            &:nth-child(1) {
              padding-left: 21px;
            }
          }
        }
      }

      .block-table__tbody {
        tr {
          td {
            padding: 8px 0px;

            text-align: start;

            border-top: 0px;
            border-bottom: 0px;
            border-left: 0px;
            border-right: 0px;

            &:nth-child(1) {
              padding-left: 21px !important;
            }
          }

          &:nth-child(odd) {
            background: #EFEFEF;
          }
        }
      }
    }

    .block-table__header {
      display: flex;
      width: 100%;
      margin-bottom: 32px;

      .header-search {

        label {
          position: relative;

          &::before {
            content: '';
            width: 20px;
            height: 20px;
            position: absolute;
            background: url(./svg/loup.svg) no-repeat center;
            top: 50%;
            right: 15px;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
          }

          input {
            width: 100%;
            height: 40px;
            outline: 0;
            padding: 0 40px 0 10px;
            border-radius: 5px;
            border: 1px solid #949494;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            color: #3c3c3c;
            letter-spacing: .5px;
            transition: border-color .15s ease-in-out;
          }
        }
      }

      .header-tabs-raiting {
        display: flex;
        align-items: center;
        margin-left: auto;

        &--items {
          display: flex;

          &-title {
            display: flex;
            align-items: center;
            margin-right: 15px;

            font-weight: 300;
            font-size: 16px;
            line-height: 19px;
            color: #949494;
          }

          &-item {
            display: flex;
            align-items: center;

            padding: 1px 20px;

            border: 1px solid var(--main_color);

            cursor: pointer;

            color: #3C3C3C;

            &.active {
              background: var(--main_color);
              color: #ffff;
            }

            &:nth-child(2) {
              border-radius: 5px 0px 0px 5px;
            }

            &:nth-child(3) {
              border-radius: 0px 5px 5px 0px;
            }
          }
        }

        @media screen and (max-width: 768px) {
          margin-left: 0px;
          margin-top: 10px;

        }
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }
  }

  &__block-filter {

    .block-filter--sex-title {
      text-align: start;
      font-size: 18px;
      line-height: 21px;
      color: #3C3C3C;
    }

    .block-filter--age {
      margin: 22px 0;

      &-radio {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      &-title {
        text-align: start;
        font-size: 18px;
        line-height: 21px;
        color: #3C3C3C;
      }
    }

    .block-filter--commands {
      margin: 20px 0;

      &-title {
        text-align: start;
        font-size: 18px;
        line-height: 21px;
        color: #3C3C3C;
        margin-bottom: 10px;
      }
    }

    .block-filter--competition {
      margin: 20px 0;

      &-title {
        text-align: start;
        font-size: 18px;
        line-height: 21px;
        color: #3C3C3C;
        margin-bottom: 10px;
      }
    }

    .block-filter--actions {
      display: flex;
      justify-content: space-between;
      margin: 20px 0;

      .reset-btn {
        text-transform: uppercase;

        font-size: 14px;
        color: #3C3C3C;

        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          bottom: 0;
          left: 0;

          border-bottom: 1px solid var(--extra_color);

          transition: all .5s linear;

          opacity: 0;
        }


        &:hover::after {
          opacity: 1;
        }
      }

      .apply-btn {
        padding: 11px 33px;


        text-transform: uppercase;
        font-size: 16px;

        color: #FFFFFF;
        background: var(--extra_color);
      }
    }

    @media screen and (max-width: 768px) {
      margin: 0px;
      padding: 0px 10px;
    }
  }
}
</style>