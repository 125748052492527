export default {
  namespaced: true,
  state: {
    festivalInfo: null,
    countries: null,
    teams: null,
  },
  actions: {},
  getters: {
    festivalInfo: (state) => state.festivalInfo,
    countries: (state) => state.countries,
    teams: (state) =>
      state.festivalInfo.teams.list.map((el) => {
        if (el.teamable) {
          return {id: el.id, name: el.teamable.name}
        }
      }),
  },
  mutations: {
    setFestivalInfo(state, payload) {
      state.festivalInfo = payload;
    },
    setCountries(state, payload) {
      state.countries = payload;
    },
  },
};
