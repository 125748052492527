<template>
 <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
  <g id="window-screen-ico" transform="translate(-45)">
    <rect id="Rectangle_1930" data-name="Rectangle 1930" width="25" height="25" transform="translate(45)" fill="#bb9657"/>
    <path id="Path_53325" data-name="Path 53325" d="M54.563,7.438,52.125,5,50,7.125l2.438,2.438-1.375,1.375L57,12,55.938,6.063Z" transform="translate(-1.875 -1.875)" fill="#fff"/>
    <path id="Path_53326" data-name="Path 53326" d="M73.363,9.563,75.8,7.125,73.675,5,71.238,7.438,69.863,6.063,68.8,12l5.938-1.062Z" transform="translate(-8.925 -1.875)" fill="#fff"/>
    <path id="Path_53327" data-name="Path 53327" d="M52.438,26.237,50,28.675,52.125,30.8l2.438-2.437,1.375,1.375L57,23.8l-5.937,1.063Z" transform="translate(-1.875 -8.925)" fill="#fff"/>
    <path id="Path_53328" data-name="Path 53328" d="M74.438,24.862,68.5,23.8l1,5.938,1.375-1.375,2.5,2.438,2.063-2.125L73,26.237Z" transform="translate(-8.813 -8.925)" fill="#fff"/>
  </g>
</svg>
</template>


<script>
export default ({})
</script>