<template>
 <div class="trial">
  <div class="trial__header">
   <div class="trial__header--block">
    <div class="background"></div>
   </div>
   <div class="title-block">
    <div class="title-container">
     <div class="trial__header--title">{{locale.labels.trial}}</div>
    </div>
   </div>
  </div>
  <router-view></router-view>
 </div>
</template>


<script>
import {mapGetters} from 'vuex';
export default ({
 data () {
  return {
    e1: 1,
    userId: null
  }
 },
 computed: {
   ...mapGetters({
      locale: 'lang/locale',
      language: 'lang/language',
      isDesktop: 'app/isDesktop',
      isMobile: 'app/isMobile'
    }),
  },
})
</script>


<style lang="scss">
.trial {
 height: 100%;
 padding-bottom: 150px;

 &__header {
  height: 100px;
  margin-bottom: -30px;
  width: 100%;
  position: relative;
  padding-bottom: 30px;
  z-index: 1;

  &::before {
   bottom: 30px;
   transform: skewY(0);
   background-color: #8F8F8F;
   content: "";
   position: absolute;
   bottom: 31px;
   left: 0;
   width: 100%;
   height: calc(100% - 34px);
   z-index: -1;
   transform-origin: left;
   transform: skewY(360deg);
  }

  &--block {
   box-shadow: none;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: calc(100% - 30px);
   z-index: -1;
   transform-origin: right;
   transform: skewY(1deg);
   overflow: hidden;

   .background {
    box-shadow: 0 15px 30px rgb(0 0 0 / 20%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: right;
    transform: skewY(360deg);
    background: linear-gradient(to right, var(--gradient_color) 0,var(--main_color) 100%);
    background-size: cover;
    background-position: center;
   
   }
  }

  .title-block {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .title-container {
    width: 100%;
    height: 100%;
    max-width: 1500px;
    position: relative;
  }

  &--title {
   padding: 15px 22px 15px 36px;

   position: absolute;
   bottom: -16px;
   left: 0px;

   font-size: 35px;


   background: var(--main_color);
   color: #fff;

   &::before {
     content: "";

     width: 94%;
     height: 109%;
    
     position: absolute;
     top: -3px;
     left: 6px;
     transform: skewY(-8deg);
     z-index: -1;

     background-color: #dcdce0;
   }
  }
 }

 &__main {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 6%;
  padding: 0 3%;

  &--blocks {
   display: grid;
   margin-top: 50px;
   grid-template-columns: repeat(2, 1fr);
   grid-column-gap: 20px;
   grid-row-gap: 20px;

   &-item {
    display: flex;
    align-items: center;
    padding: 20px;

    border: 1px solid var(--extra_color);
    border-radius: 4px;

    cursor: pointer;

    font-size: 14px;

    &:hover {
     background: #EFEFEF;
    }
   }

   &-icon {
    margin-right: 20px;
   }
  }

  &--search {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: flex-end;

   .search-title {
     display: flex;
     align-items: flex-end;
     font-family: Roboto;
     font-style: normal;
     font-weight: normal;
     font-size: 26px;
     line-height: 30px;
   }

   label {
    position: relative;

    &::before {
     content: '';
     width: 20px;
     height: 20px;
     position: absolute;
     background: url(./svg/loup.svg) no-repeat center;
     top: 50%;
     right: 15px;
     -webkit-transform: translateY(-50%);
     -ms-transform: translateY(-50%);
     transform: translateY(-50%);
    }

    input {
     width: 460px;
     height: 40px;
     outline: 0;
     padding: 0 40px 0 10px;
     border-radius: 5px;
     border: 1px solid #949494;
     font-family: 'Roboto',sans-serif;
     font-size: 14px;
     color: #3c3c3c;
     letter-spacing: .5px;
     transition: border-color .15s ease-in-out;
    }
   }
  }
 }
}
</style>