<template>
  <div class="agree-en">
   <div class="agree-en__participation">
    <h1>CONSENT TO PARTICIPATION IN THE QUALIFICATION TESTS OF GTO COMPLEX AT THE SITE OF GTO COMPLEX {{name}}
    </h1>
    <p>I hereby voluntarily agree to participate in the qualification tests of GTO Complex while being at the specially equipped site of GTO Complex located on the territory of the {{namePlace}}, I confirm that I have no restrictions and contraindications for physical culture and sports, adequately perceive information about safety measures, and I will observe personal and social norms, rules of conduct, control my health and safety. Having full freedom of expression of will, being able to fully understand and adequately assess the level of my physical shape, the degree of mastery of the techniques and skills of the sporting discipline in which I plan to participate during test of GTO Complex, by signing this Agreement, I confirm that I have sufficient preparation for the participation, which allows to exclude accidental damage to my health and life.</p>
    <br>
    <p>At the same time, I understand and realise that the danger and the possibility of risk of participation in the qualification tests of GTO Complex include (but are not limited to) severe injuries to body parts, which in turn can cause partial or complete disability, possible permanent paralysis and death; in addition, my overall health and well-being can be damaged, and that failure to follow safety rules can lead to infliction of harm to my health. No amount of reasonable observation of participants, as well as training or protective equipment cannot exclude all risks and dangers.</p>
    <br>
    <p>Taking into account the danger of participation in the qualification tests of GTO Complex, I realise the importance of following the instructions of a sports instructor regarding the technique of performance of qualification tests of GTO Complex, as well as other rules, and agree to follow these instructions.</p>
    <br>
    <p>If during my presence or participation in the qualification tests of GTO Complex I find a threat to my life and health, I undertake to terminate my participation in this event and immediately inform the identified threat to nearby representatives of the Directorate.</p>
    <br>
    <p>I confirm that the representatives of the Directorate have informed, warned and cautioned me that I could be seriously injured, paralysed or perish while participating in the qualification tests of GTO Complex.</p>
    <br>
    <p>Taking part in the qualification tests of GTO Complex, by this agreement, I assume all the risks associated with participation in this event, and I release the ANO "Directorate for Sports and Social Projects" (hereinafter referred to as "Directorate", legal address: 420111, Kazan, 52/7, Baumana Str.), its employees or representatives, from any liability (fully permitted by law) and all (any) claims, settlements of accounts, compensation, and legal claims or any demands that may arise in connection with my participation in the qualification tests of GTO Complex. These terms and conditions of the agreement serve as acceptance of risks and renunciation of all present and future claims of my heirs, testamentary executors, legal representative, legal successors and all members of my family, close relatives, as well as claims for property.</p>
    <br>
    <p>Also, I hereby express my consent to assume all responsibility for possible risks associated with damage to my property during the qualification tests of GTO Complex.</p>
    <br>
    <p>I hereby authorise the Directorate to photograph and record my voice and demonstrations, as well as the use of my photographs, silhouettes and other materials that involve reproduction of my appearance and voice, for marketing purposes within the context of this event and for events to be held in subsequent years.</p>
    <br>
    <p>I acknowledge that audio, video, camera and/or photo recording of my demonstration can be performed, and by giving consent to photographing and/or video recording, I gratuitously transfer the rights listed in the abovementioned paragraph to the Directorate.</p>
    <br>
    <p>I have read and understood the rules for participation in qualification tests of GTO Complex.</p>
    <br>
    <p>I understand and realise the importance of the abovementioned information and warnings, and I sign this agreement voluntarily and without any coercion. I realise that putting a "V" sign in the field mean my written consent to the conditions described above.</p>
    <br>
    <h1>CONSENT TO THE PROCESSING OF PERSONAL DATA</h1>
    <p>I, hereinafter referred to as the "Personal Data Subject", in compliance with the requirements of Federal Law No. 152-FZ "On Personal Data" dated 27 July, 2006 freely, with my will and in my interest, hereby give my consent to ANO " Directorate for Sports and Social Projects” (Hereinafter referred to as the "Directorate", legal address: 420111, Kazan, 52/7, Baumana Str.), for processing of my personal data stated during registration for performance of the qualification tests of GTO.</p>
    <br>
    <p>Complex on the site of GTO Complex at the {{name}} in {{namePlace}}.</p>
    <br>
    <p>Under personal data, I understand any information relating to me as a Personal Data Subject, including my surname, name, patronymic, sex, date of birth, registration address, residential address, contact phone number(s), e-mail address, passport data, Individual Taxpayer Identification Number, Personal Pension Account Number at the Pension Fund of the Russian Federation, and information about my health and place of employment/study and other information. Under processing of personal data, I understand collection, classification, accumulation, clarification, updating, modification, use, distribution, transfer, depersonalisation, blocking, destruction, termless storage and any other actions (operations) with personal data.</p>
    <br>
    <p>Processing of the personal data of a Personal Data Subject is executed solely for the purpose of registration of the Personal Data Subject in the system for performance of the qualification tests of GTO.</p>
    <br>
    <p>Processing of the personal data of a Personal Data Subject can be executed with automation equipment and/or without automation equipment in accordance with the current legislation of the Russian Federation and the internal provisions of the Directorate.</p>
    <br>
    <p>The Directorate takes the necessary legal, organisational and technical measures or ensure their provisioning for protection of personal data from unauthorised or accidental access to it, destruction, modification, blocking, copying, provision, dissemination of personal data, as well as other illegal actions with personal data, and also assumes the obligation to maintain the confidentiality of the personal data of the Personal Data Subject. The Directorate has the right to involve subcontractors for processing of personal data of a Personal Data Subject and has the right to transfer personal data for processing to its affiliated entities or third parties, while ensuring that such subcontractors and affiliated entities take appropriate obligations regarding confidentiality of the personal data.</p>
    <br>
    <p>I have read and understood that:</p>
    <p>1) the present consent to the processing of my personal data, stated during registration for performance of the qualification tests of GTO Complex, acts over a period of fifty (50) years from the date of such registration;</p>
    <p>2) the consent can be withdrawn by me on the basis of a written application prepared in any form;</p>
    <p>3) provision of personal data of third persons without their consent entails responsibility in accordance with the current legislation of the Russian Federation.</p>
    <br>
    <p>I realise that putting a "V" sign in the field mean my written consent to the conditions described above.</p>
   </div>
  </div>
</template>


<script>
export default ({
  props: {
   name: {
    required: true
   },
   namePlace: {
     required: true
   }
  }
})
</script>

<style lang="scss">
.agree-en {
  width: 1000px;
  max-height: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
  padding: 50px;
  position: relative;

  h1 {
   text-align: center;
   margin-bottom: 20px;
  }

  p {
   text-align: start;
  }

  @media screen and (max-width: 768px) {
    padding: 0 20px;
    min-width: 220px;
    min-height: 220px;
    width: 100%;
    height: 100%;
  }

  &__title {
    margin-top: auto;
    width: 100%;
    font-size: 42px;
    font-weight: bold;
  }

  &__description {
    width: 100%;

    font-size: 28px;
    font-weight: 300;
  }

  &__action {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;

    button {
    padding: 5px 18px; 

    background: var(--extra_color);

    color: #FFFFFF
    }
  }
  
}
</style>